import { parseXml2, testXml2 } from "./polyfill-DomParser";
import {createMessageToServer, responseToString, ResponseFromServer, getCommandName, MessageToServer} from "./talk-with-server";
import {Worker, RegisteredWindow, UserConnection, WorkerMessage, WorkerMessageFunction, WorkerMessageType } from "./worker";
import { Connection, ServerCommand, decodeServerTime, encodeDate} from "../services/connection.client";
import { QuoteFeedManager } from "../services/models/quote-feed/trade-quote.manager";
import { TopListDataResponseModel } from "../services/models/top-list/top-list-data-response.model";
import { BrokerPortfolio } from "../services/models/broker/broker-portfolio";
import { BrokerAccount } from "../services/models/broker/broker-account";
import { BrokerPosition } from "../services/models/broker/broker-position";
import { BrokerOrder } from "../services/models/broker/broker-order";
import { TradeQuote } from "../services/throttled-market-data.client";
import { BrokerExecution } from "../services/models/broker/broker-execution";
import { BrokerInstrument } from "../services/models/broker/broker-instrument";
import { ProfitBar } from "../services/models/broker/profit-bar";
import { ProfitBackfillClient } from "../services/profit-backfill.client";
import { ClientResponseModel } from "../services/models/client-response/client-response.model";
import { ProfitBackfillRequestModel } from "../services/models/profit-backfill/profit-backfill-request.model";
import { IdGenerator } from "../services/models/helpers/id-generator";
import { SingleGridResponse } from "../javascript-api/single-grid-request";
import axios, { AxiosResponse, AxiosError } from 'axios';
import { AppSettings } from "../app-settings";

export class PaperManagerWorker {

    private instanceId;
    private nextOrderId:number = 0;
    private brokerPortfolios: Map<string, BrokerPortfolio>;
    private paperListenCancelToken: any;
    private paperListenOrdersCancelToken: any;
    private paperListenPositionsCancelToken: any;
    private tradeQuoteManager: QuoteFeedManager;
    private instruments: Map<string, BrokerInstrument>;
    private symbolBars: Map<string, SingleGridResponse> | undefined;
    private instrumentsInitialized: boolean = false;
    private profitBarsInitialized: boolean = false;
    private recalculateIntervalId: number | undefined = undefined;
    private initialized: boolean = false;
    private userConnection: any;
    private worker: Worker;

    public constructor(worker:Worker, userConnection:UserConnection) {

        this.worker = worker;
        this.instanceId = IdGenerator.NewId();
        this.brokerPortfolios = new Map<string, BrokerPortfolio>(); 
        this.tradeQuoteManager = QuoteFeedManager.getInstance();
        this.instruments = new Map<string, BrokerInstrument>(); 
        this.userConnection = userConnection;
    }

    public Disconnect(){

        this.initialized = false;

        if(this.recalculateIntervalId) { 
           clearInterval(this.recalculateIntervalId);
           this.recalculateIntervalId = undefined;
        }

        //Disconnect to Updates Channel 
        let messageToServer = [
            ["command", "paper_command"],
            ["subcommand", "paper_remove_listener"],
        ];
     
        this.nextOrderId = 0;
        this.brokerPortfolios.clear();
        this.recalculateIntervalId = undefined;
        this.initialized = false;
        this.profitBarsInitialized = false;
        
        if (this.symbolBars){
           this.symbolBars.clear();
           this.symbolBars = undefined;
        }
    }

    public Connect(broker: string)
    {
        if(broker === "Paper")
        {
            if(!this.initialized) {
               this.initialized = true;
               this.InitializePaperTrading(broker);
            }
        }
    }

    public ProcessPaperRequestMessage( workerMessage: WorkerMessage){

        switch(workerMessage.function){

            case WorkerMessageFunction.BrokerPortfolioRequest:

            //Raise Portfolio Callbacks 
                const portfolio = this.brokerPortfolios.get("Paper");
                const registeredWindow = this.registeredWindowFromConnection(this.userConnection, workerMessage.windowId )

                if(portfolio && registeredWindow){
          
                    //These callbacks are fired in the same order as the orginal way they come from the server 
                    if (portfolio.Accounts.size > 0) {
                        let workerMessageResponse: WorkerMessage = { 
                            type: WorkerMessageType.PaperResponse,
                            function : WorkerMessageFunction.BrokerAccounts,
                            userName: this.userConnection.user.username,
                            windowId: workerMessage.windowId,
                            message : [Array.from(portfolio.Accounts.values())]
                        }
                        this.sendMessage(registeredWindow,workerMessageResponse);
                    }

                    if( portfolio.Executions.size > 0){

                        let workerMessageResponse: WorkerMessage = { 
                            type: WorkerMessageType.PaperResponse,
                            function : WorkerMessageFunction.BrokerExecutions,
                            userName: this.userConnection.user.username,
                            windowId: workerMessage.windowId,
                            message : [Array.from(portfolio.Executions.values())]
                        }
                        this.sendMessage(registeredWindow,workerMessageResponse);
                    }

                    if( portfolio.Orders.size > 0){

                        let workerMessageResponse: WorkerMessage = { 
                            type: WorkerMessageType.PaperResponse,
                            function : WorkerMessageFunction.BrokerOrders,
                            userName: this.userConnection.user.username,
                            windowId: workerMessage.windowId,
                            message : [Array.from(portfolio.Orders.values())]
                        }
                        this.sendMessage(registeredWindow,workerMessageResponse);
                    }

                    if( portfolio.Positions.size > 0){

                        let workerMessageResponse: WorkerMessage = { 
                            type: WorkerMessageType.PaperResponse,
                            function : WorkerMessageFunction.BrokerPositions,
                            userName: this.userConnection.user.username,
                            windowId: workerMessage.windowId,
                            message : [Array.from(portfolio.Positions.values())]
                        }
                        this.sendMessage(registeredWindow,workerMessageResponse);
                    }

                    break;
            }
            case WorkerMessageFunction.BrokerOrderCancel:

                this.CancelOrder(workerMessage.message);

                break;

            case WorkerMessageFunction.BrokerOrderSubmit:

                this.SubmitOrder(workerMessage.message);

                break;

            case WorkerMessageFunction.BrokerOrderModify:

                this.ModifyOrder(workerMessage.message);

                break;

            case WorkerMessageFunction.BrokerPositionAdd:

                this.PositionAdd(workerMessage);
                
                break;

        }

    }

    private PositionAdd(inWorkerMessage:WorkerMessage) {
        
        const registeredWindow = this.registeredWindowFromConnection(this.userConnection, inWorkerMessage.windowId )

        let outWorkerMessageResponse: WorkerMessage = { 
            type: WorkerMessageType.PaperResponse,
            function : WorkerMessageFunction.BrokerPositionAddResponse,
            userName: this.userConnection.user.username,
            windowId: inWorkerMessage.windowId,
            message : false
        }

        if( !registeredWindow)
            return;

        const portfolio = this.brokerPortfolios.get("Paper");

        if( !portfolio){
            this.sendMessage(registeredWindow,outWorkerMessageResponse);
            return;
        }

        let brokerPosition = inWorkerMessage.message as BrokerPosition;

        let position = portfolio.Positions.get(brokerPosition.AccountId + "_" + brokerPosition.Symbol);

        if (position){
            this.sendMessage(registeredWindow,outWorkerMessageResponse);
            return;
        }

        let created = 0;  
    
        if(brokerPosition.Created)
           created = encodeDate(brokerPosition.Created);

        let messageToServer = createMessageToServer([
            ["command",    "paper_command"],
            ["subcommand", "paper_add_position"],
            ["avg_price", brokerPosition.LastPrice],
            ["symbol", brokerPosition.Symbol],
            ["entry_time", created],
            ["shares", brokerPosition.Shares],
            ["is_long", brokerPosition.Shares > 0 ? "1" : "0"],
            ["account_id", brokerPosition.AccountId],
            ["entry_method", brokerPosition.EntryMethod ?? "manual"],

        ]);

        this.userConnection.connectionMaster?.outbox.sendWithStreamingResponse(messageToServer, this.PaperMessageReceived.bind(this));

        outWorkerMessageResponse.message = true;

        this.sendMessage(registeredWindow,outWorkerMessageResponse);

    }

    private CancelOrder(orderId:string){
        
        let messageToServer = createMessageToServer([
            ["command",    "paper_command"],
            ["subcommand", "paper_cancel_order"],
            ["id", orderId]
        ]);
        this.userConnection.connectionMaster?.outbox.sendWithStreamingResponse(messageToServer, this.PaperMessageReceived.bind(this));
    }

    private SubmitOrder(brokerOrder:BrokerOrder){
        
        let goodUntilTime = 0;  
        let goodAfterTime = 0;  

        if(brokerOrder.GoodUntilTime)
           goodUntilTime = encodeDate(brokerOrder.GoodUntilTime);

        if(brokerOrder.GoodAfterTime)
           goodAfterTime = encodeDate(brokerOrder.GoodAfterTime);

        let messageToServer = createMessageToServer([
            ["command",    "paper_command"],
            ["subcommand", "paper_add_order"],
            ["account_id", brokerOrder.AccountId],
            ["symbol", brokerOrder.Symbol],
            ["shares", brokerOrder.Shares],
            ["order_type", brokerOrder.TradeType],
            ["is_long", brokerOrder.Side == "Buy" ? "1" : "0"],
            ["oca_group", brokerOrder.OcaGroup],
            ["good_till_time", goodUntilTime],
            ["local_id", this.nextOrderId],
           // ["reference_id", brokerOrder.OrderReference],
           // ["parent_id", brokerOrder.ParentOrderId],
            ["limit_price", brokerOrder.LimitPrice],
            ["stop_price", brokerOrder.StopPrice],
            ["good_till_time", goodUntilTime],
            ["good_after_time", goodAfterTime],
            ["outside_rth", brokerOrder.OutsideRTH ? "1" : "0"]
        ]);
        this.nextOrderId++;
        this.userConnection.connectionMaster?.outbox.sendWithStreamingResponse(messageToServer, this.PaperMessageReceived.bind(this));
    }

    /**
     * TODO: Share the messageToServer logic between SubmitOrder and ModifyOrder, 
     * possibly with a new method like buildOrderMessage that has an orderId parameter
     */
    private ModifyOrder(brokerOrder:BrokerOrder){
        
        let goodUntilTime = 0;  
        let goodAfterTime = 0;  

        if(brokerOrder.GoodUntilTime)
           goodUntilTime = encodeDate(brokerOrder.GoodUntilTime);

        if(brokerOrder.GoodAfterTime)
           goodAfterTime = encodeDate(brokerOrder.GoodAfterTime);

        let messageToServer = createMessageToServer([
            ["command",    "paper_command"],
            ["subcommand", "paper_add_order"],
            ["account_id", brokerOrder.AccountId],
            ["symbol", brokerOrder.Symbol],
            ["shares", brokerOrder.Shares],
            ["order_type", brokerOrder.TradeType],
            ["is_long", brokerOrder.Side == "Buy" ? "1" : "0"],
            ["oca_group", brokerOrder.OcaGroup],
            ["good_till_time", goodUntilTime],
            ["local_id", brokerOrder.OrderId],
           // ["reference_id", brokerOrder.OrderReference],
           // ["parent_id", brokerOrder.ParentOrderId],
            ["limit_price", brokerOrder.LimitPrice],
            ["stop_price", brokerOrder.StopPrice],
            ["good_till_time", goodUntilTime],
            ["good_after_time", goodAfterTime],
            ["outside_rth", brokerOrder.OutsideRTH ? "1" : "0"]
        ]);

        this.userConnection.connectionMaster?.outbox.sendWithStreamingResponse(messageToServer, this.PaperMessageReceived.bind(this));
    }

    
    private MarketDataWorkerMessageReceived(workerMessage:WorkerMessage){

        try{ 

        let marketDataMessage = workerMessage.message;

        let message = JSON.parse(marketDataMessage);

                switch(message.MessageType)
                {
                    //Connection Opened 30:
                    //Heartbeat 31:
                    case 30:
                    case 31:
                        break;
                    case 53:

                        //TradeQuote
                        let prevClosePrice = message.Data[12];
                        let dollarChange =  (message.Data[3] - prevClosePrice);
                        let percentChange = (dollarChange/prevClosePrice)*100;

                        let tradeQuote:TradeQuote = {
                            Symbol: message.Data[0],
                            TradeTime: new Date(message.Data[1]),
                            TradeTimeUtc: new Date(message.Data[11]),
                            TradeSize: message.Data[2],
                            TradePrice: message.Data[3].toFixed(2),
                            QuoteTime: new Date(message.Data[4]),
                            BidSize: message.Data[5],
                            Bid: message.Data[6],
                            AskSize: message.Data[7],
                            Ask: message.Data[8],
                            Volume: message.Data[9],
                            ReportingExchange: message.Data[10],
                            DollarChange: Number(dollarChange.toFixed(4)),
                            PercentChange: Number(percentChange.toFixed(4)),
                            PreviousClose: prevClosePrice
                        };

                        let instrument =  this.instruments.get(tradeQuote.Symbol);
                        
                        let newInstrument = false;

                        if(!instrument)
                        {
                            newInstrument = true;  
                            instrument = new BrokerInstrument();
                            instrument.Created = new Date();
                            instrument.Symbol = tradeQuote.Symbol;
                        }
            
                        instrument.Last = tradeQuote.TradePrice ?? 0;
                        instrument.ChangeFromCloseDollar  = dollarChange;
                        instrument.ChangeFromClosePercent = percentChange;
                        instrument.LastClose = prevClosePrice;
                        instrument.TradeSize = tradeQuote.TradeSize;        
                        instrument.BidSize = tradeQuote.BidSize;
                        instrument.Bid = tradeQuote.Bid;
                        instrument.AskSize = tradeQuote.AskSize;
                        instrument.Ask = tradeQuote.Ask;
                        instrument.TotalVolume = tradeQuote.Volume;
                        instrument.ReportingExchange = tradeQuote.ReportingExchange;
    
                        if( newInstrument)
                           this.instruments.set(tradeQuote.Symbol, instrument);

                        break;
                }
            }
            catch(e){
                console.log("MarketDataWorkerMessageReceived: " + e);
            }
    }

    public subscribeMarketData(symbol:string)
    {

        if(!this.userConnection.user ) return;

        let serverCommand = [
            ["command",    "market_data"],
            ["subcommand", "subscribe"],
            ["symbol",      symbol]
        ] as ServerCommand;

        const command = serverCommand[0][1] as string

        const workerMessage: WorkerMessage = {
            type: WorkerMessageType.SubscribeMarketData,
            userName: this.userConnection.user.username, 
            windowId: this.instanceId,
            message: serverCommand,
            command: command
        };
        
        this.worker.subscribeMarketData(workerMessage);
    
    }

    private InitializePaperTrading(broker: string){


        this.worker.addRegisteredWindow(this.userConnection.user.username, this.instanceId, this.MarketDataWorkerMessageReceived.bind(this))

        this.brokerPortfolios.set(broker, new BrokerPortfolio())

        //Subscribe to Updates Channel 
        let updatesMessage = createMessageToServer([ ["command", "paper_command"], ["subcommand", "paper_add_listener"] ]);
     
        this.userConnection.connectionMaster?.outbox.sendWithStreamingResponse(updatesMessage, this.PaperMessageReceived.bind(this));

        //Subscribe to Orders Channel 
        let ordersMessage = createMessageToServer([
            ["command", "paper_command"],
            ["subcommand", "paper_list_orders"],
        ]);
    
        this.userConnection.connectionMaster?.outbox.sendWithStreamingResponse(ordersMessage, this.PaperMessageReceived.bind(this));

        //Subscribe to Positions Channel 
        let positionsMessage = createMessageToServer([
            ["command", "paper_command"],
            ["subcommand", "paper_list_positions"],
        ]);
    
        this.paperListenPositionsCancelToken = this.userConnection.connectionMaster?.outbox.sendWithStreamingResponse(positionsMessage, this.PaperMessageReceived.bind(this));
    }

    private async PaperMessageReceived(response:ResponseFromServer){
        
        const responseAsString = responseToString(response);

        const xml = parseXml2(responseAsString);
    
        if(!xml)
            return;
   
        const type = xml?.getAttribute("type")
        
        switch(type)
        {
            case "paper_ping":
                break;
            case "order_status":
                await this.BrokerOrderResponse(xml);
                break;   
            case "orders":
                await this.BrokerOrdersResponse(xml);
                break;   
            case "position":
            case "positions":
                await this.BrokerPositionsResponse(xml);
                break;
            case "execution_report":
                await this.BrokerExecutionsResponse(xml);
                break;   
            case "remove_account":
                break;   
            case "account_update":
                await this.AccountUpdateResponse(xml);
                break;
            case "accounts_update":
                await this.AccountsUpdateResponse(xml);
                break;
            case "next_order_id":
                await this.NextOrderIdResponse(xml);
                break;   
            case "quote":
                break;
            case "unauthorized":
                break;
            default:
                console.log("[Unknown Paper Response] type=" + type + ", " + response);
                break;
        }
    }

    private NextOrderIdResponse(xml:Element)
    {
        this.nextOrderId = Number(xml.getAttribute("next_order_id") ?? 0);
    }

    //Includes All 
    private async BrokerExecutionsResponse(xml:Element)
    {
        const portfolio = this.brokerPortfolios.get("Paper");
        
        if(!portfolio) return;

        const count = Number(xml.getAttribute("count"));

        let newBrokerExecutions = new Array<BrokerExecution>();

        const includesAll = Boolean(xml.getAttribute("includes_all"));

        for (let i = 1; i <= count; i++)
        {

            const id = xml.getAttribute("id" + i) ?? "";
            const account_id = xml.getAttribute("account_id" + i) ?? "";
            const order_id = xml.getAttribute("order_id" + i) ?? "";
            const symbol  = xml.getAttribute("symbol" + i) ?? "";
            const { position:brokerPosition} = await this.getBrokerPosition(account_id, symbol, portfolio);            
            const fill_time = xml.getAttribute("fill_time" + i) ?? "";
            const executionKey = order_id + "_" + symbol +  "_" + fill_time; 

            let brokerExecution = new BrokerExecution()
           
            portfolio.Executions.set(executionKey, brokerExecution);
           
            newBrokerExecutions.push(brokerExecution);
            
            brokerExecution.Id = id;
            brokerExecution.AccountId = account_id;
            brokerExecution.OrderId = order_id;
            brokerExecution.Side = (xml.getAttribute("is_long" + i) ?? "") == "1" ? "Buy" : "Sell"; 
            brokerExecution.Symbol = symbol; 
            brokerExecution.Shares = Number(xml.getAttribute("shares" + i)); 
            brokerExecution.FilledPrice = Number(xml.getAttribute("fill_price" + i)); 
            brokerExecution.Commissions = Number(xml.getAttribute("commissions" + i)); 
            brokerExecution.Filled  = decodeServerTime(xml.getAttribute("fill_time" + i) ?? undefined);

            //Calculated Positon Fields 
            if( brokerPosition)
            {
                brokerPosition.Commissions += brokerExecution.Commissions;

                if(brokerExecution.Side === "Buy" ){
                  brokerPosition.NetSharesTradedToday += brokerExecution.Shares;
                  brokerPosition.NetMoneyTradedToday  += brokerExecution.Shares * brokerExecution.FilledPrice;   
                }
                else {
                  brokerPosition.NetSharesTradedToday -= brokerExecution.Shares;
                  brokerPosition.NetMoneyTradedToday  -= brokerExecution.Shares * brokerExecution.FilledPrice;   
                }
            }
        }

        const brokerExecutions = Array.from(portfolio.Executions.values());

        let workerMessage: WorkerMessage = {
            type: WorkerMessageType.PaperResponse,
            function : includesAll ?  WorkerMessageFunction.BrokerExecutions  : WorkerMessageFunction.BrokerExecutionsAdd,
            userName: this.userConnection.user.username,
            windowId: "",
            message : [brokerExecutions, newBrokerExecutions]
        }

        for (const [registeredWindowKey, registeredWindow] of this.userConnection.registeredWindows.entries()) {
           workerMessage.windowId = registeredWindowKey;
           this.sendMessage(registeredWindow, workerMessage);
        };

    }

    //Only At startup 
    private async BrokerOrdersResponse(xml:Element)
    {
        const portfolio = this.brokerPortfolios.get("Paper");
        
        if(!portfolio) return;

        const count = Number(xml.getAttribute("count"));

        for (let i = 1; i <= count; i++)
        {

            const account_id = xml.getAttribute("account_id" + i) ?? "";
            const local_id = xml.getAttribute("local_id" + i) ?? "";
            const symbol  = xml.getAttribute("symbol" + i) ?? "";

            const orderKey = account_id + "_" + local_id; 

            let brokerOrder = portfolio.Orders.get(orderKey);

            if (!brokerOrder)
            {
               brokerOrder = new BrokerOrder();
               brokerOrder.Key = orderKey;
               portfolio.Orders.set(orderKey, brokerOrder);  
            }

            brokerOrder.AccountId = account_id;
            brokerOrder.OrderId = local_id;
            brokerOrder.Side = (xml.getAttribute("is_long" + i) ?? "") == "1" ? "Buy" : "Sell"; 
            brokerOrder.IsLong = (xml.getAttribute("is_long" + i) ?? "") == "1"; 
            brokerOrder.Symbol = symbol; 
            brokerOrder.Status = xml.getAttribute("status" + i) ?? "";
            brokerOrder.TradeType = xml.getAttribute("order_type" + i) ?? "Unknown";
            brokerOrder.OcaGroup =  xml.getAttribute("oca_group" + i) ?? "";
            brokerOrder.Shares =  Number(xml.getAttribute("shares" + i));
            brokerOrder.FilledShares =  Number(xml.getAttribute("shares" + i));
            brokerOrder.FilledPrice = Number(xml.getAttribute("fill_price" + i)); 
            brokerOrder.StopPrice = Number(xml.getAttribute("stop_price" + i)); 
            brokerOrder.LimitPrice = Number(xml.getAttribute("limit_price" + i));
            if(xml.getAttribute("good_till_time" + i) ?? undefined)
               brokerOrder.GoodUntilTime = decodeServerTime(xml.getAttribute("good_till_time" + i) ?? undefined);
            if(xml.getAttribute("good_after_time" + i) ?? undefined)
               brokerOrder.GoodAfterTime = decodeServerTime(xml.getAttribute("good_after_time" + i) ?? undefined);
            brokerOrder.Created = decodeServerTime(xml.getAttribute("created" + i) ?? undefined);
            brokerOrder.Filled  = decodeServerTime(xml.getAttribute("fill_time" + i) ?? undefined);
            if(brokerOrder.Filled )
               brokerOrder.Updated = brokerOrder.Filled;
            else
               brokerOrder.Updated = brokerOrder.Created;
            
        }

        const brokerOrders = Array.from(portfolio.Orders.values());

        let workerMessage: WorkerMessage = {
            type: WorkerMessageType.PaperResponse,
            function : WorkerMessageFunction.BrokerOrders,
            userName: this.userConnection.user.username,
            windowId: "",
            message : [brokerOrders]
        }

        for (const [registeredWindowKey, registeredWindow] of this.userConnection.registeredWindows.entries()) {
           workerMessage.windowId = registeredWindowKey;
           this.sendMessage(registeredWindow, workerMessage);
        };
    }

    private async BrokerOrderResponse(xml:Element)
    {
        const portfolio = this.brokerPortfolios.get("Paper");
        
        if(!portfolio) return;

        const account_id = xml.getAttribute("account_id") ?? "";
        const local_id = xml.getAttribute("local_id") ?? "";
        const symbol  = xml.getAttribute("symbol") ?? "";
        
        const orderKey = account_id + "_" + local_id; 

        let newOrder = false;

        let brokerOrder = portfolio.Orders.get(orderKey);

        if (!brokerOrder)
        {
            brokerOrder = new BrokerOrder();
            brokerOrder.Key = orderKey;
            portfolio.Orders.set(orderKey, brokerOrder);  
            newOrder = true;
        }

        brokerOrder.AccountId = account_id;
        brokerOrder.OrderId = local_id;
        brokerOrder.IsLong = (xml.getAttribute("is_long") ?? "") == "1"; 
        brokerOrder.Side = (xml.getAttribute("is_long") ?? "") == "1" ? "Buy" : "Sell"; 
        brokerOrder.Symbol = symbol; 
        brokerOrder.Status = xml.getAttribute("status") ?? "";
        brokerOrder.TradeType = xml.getAttribute("order_type") ?? "Unknown";
        brokerOrder.OcaGroup =  xml.getAttribute("oca_group") ?? "";
        brokerOrder.Shares =  Number(xml.getAttribute("shares"));
        brokerOrder.FilledShares =  Number(xml.getAttribute("shares"));
        brokerOrder.FilledPrice = Number(xml.getAttribute("fill_price")); 
        brokerOrder.StopPrice = Number(xml.getAttribute("stop_price")); 
        brokerOrder.LimitPrice = Number(xml.getAttribute("limit_price"));

        if (xml.getAttribute("is_entry_order") ?? undefined) {
            brokerOrder.IsEntryOrder = xml.getAttribute("is_entry_order") == "1"; 
        }

        if(xml.getAttribute("good_till_time") ?? undefined)
        {
            if(xml.getAttribute("good_till_time") != "0" )
               brokerOrder.GoodUntilTime = decodeServerTime(xml.getAttribute("good_till_time") ?? undefined);
        }
        if(xml.getAttribute("good_after_time") ?? undefined)
        {
            if(xml.getAttribute("good_after_time") != "0" )
                brokerOrder.GoodAfterTime = decodeServerTime(xml.getAttribute("good_after_time") ?? undefined);
        }

        if( brokerOrder.Status == "filled" )
        {
            brokerOrder.Filled = decodeServerTime(xml.getAttribute("fill_time") ?? undefined);
            brokerOrder.Created = brokerOrder.Filled;
            brokerOrder.Updated = brokerOrder.Filled;
        }
        else{
            brokerOrder.Created = new Date();
            brokerOrder.Updated = brokerOrder.Created;
        }

        if(xml.getAttribute("error_reason"))
            brokerOrder.ErrorReason = xml.getAttribute("error_reason") ?? undefined;

        const brokerOrders = Array.from(portfolio.Orders.values());

        let workerMessage: WorkerMessage = {
            type: WorkerMessageType.PaperResponse,
            function : newOrder ? WorkerMessageFunction.BrokerOrderAdd : WorkerMessageFunction.BrokerOrderUpdate,
            userName: this.userConnection.user.username,
            windowId: "",
            message : [brokerOrders, brokerOrder]
        }

        for (const [registeredWindowKey, registeredWindow] of this.userConnection.registeredWindows.entries()) {
           workerMessage.windowId = registeredWindowKey;
           this.sendMessage(registeredWindow, workerMessage);
        };
    }

    //Includes All
    private async BrokerPositionsResponse(xml:Element)
    {
        const portfolio = this.brokerPortfolios.get("Paper");
        
        if(!portfolio) return;

        const count = Number(xml.getAttribute("count"));

        const includesAll = Boolean(xml.getAttribute("includes_all"));

        console.log("BrokerPositionsResponse: " + includesAll);

        let newPosition = false;
        let brokerPosition:BrokerPosition | null = null;
   
        for (let i = 1; i <= count; i++)
        {
            const account_id = xml.getAttribute("account_id" + i) ?? "";
            
            const symbol = xml.getAttribute("symbol" + i) ?? "";

            const {added, position} = await this.getBrokerPosition(account_id, symbol, portfolio);            

            newPosition = added;

            brokerPosition = position;
            brokerPosition.InstanceId = this.instanceId;
            brokerPosition.AccountId = account_id;
            brokerPosition.Symbol = symbol; 
            brokerPosition.AverageCost = Number(xml.getAttribute("avg_open_price" + i)); 
            brokerPosition.Shares = Number(xml.getAttribute("shares" + i)); 
            brokerPosition.SharesBought = Number(xml.getAttribute("bought_shares" + i)); 
            brokerPosition.SharesSold = Number(xml.getAttribute("sold_shares" + i)); 
            brokerPosition.UnrealizedProfitLoss = Number(xml.getAttribute("open_profit" + i)); 
            brokerPosition.RealizedProfitLoss = Number(xml.getAttribute("closed_profit" + i)); 
            brokerPosition.TotalProfit = (brokerPosition.UnrealizedProfitLoss ?? 0) + (brokerPosition.RealizedProfitLoss ?? 0)
            brokerPosition.LastPrice = Number(xml.getAttribute("last" + i));
            brokerPosition.iLast = brokerPosition.LastPrice;
            brokerPosition.MarketValue = brokerPosition.Shares * brokerPosition.LastPrice;
            brokerPosition.Created =  decodeServerTime(xml.getAttribute("created" + i) ?? undefined)
        }

        const brokerPositions = Array.from(portfolio.Positions.values());
        
        if( includesAll && !this.recalculateIntervalId && !this.symbolBars){

            //We Start With a 1/2 Second Recalculate Interval while waiting for initial data
            //Then we Will switch to a 10 second recalculate window.    
            this.getBars(brokerPositions);
            this.recalculateIntervalId = self.setInterval(this.recalculate.bind(this), 500) as number;
        }

        let data = null;
        let workerMessageFunction = null; 

        if(includesAll)
        {
            data = [brokerPositions];
            workerMessageFunction = WorkerMessageFunction.BrokerPositions;
        }
        else
        {
          if( newPosition){
            data = [brokerPositions, brokerPosition];
            workerMessageFunction = WorkerMessageFunction.BrokerPositionAdd;
          }
          else{
            data = [brokerPositions];
            workerMessageFunction = WorkerMessageFunction.BrokerPositionsUpdate;
          }
        }

        let workerMessage: WorkerMessage = {
            type: WorkerMessageType.PaperResponse,
            function : workerMessageFunction,
            userName: this.userConnection.user.username,
            windowId: "",
            message : data
        }

        for (const [registeredWindowKey, registeredWindow] of this.userConnection.registeredWindows.entries()) {
           workerMessage.windowId = registeredWindowKey;
           this.sendMessage(registeredWindow, workerMessage);
        };

    }
   
    private async AccountsUpdateResponse(xml:Element)
    {
        let newAccount = false;

        const portfolio = this.brokerPortfolios.get("Paper");
        
        if(!portfolio) return;

        const count = Number(xml.getAttribute("count"))

        for (let i = 1; i <= count; i++)
        {
            let accountName = xml.getAttribute("name" + i);
            if( accountName === "Simulated Trading Default"){
                accountName = "TI Paper Trading";
            }

            if(!accountName) continue;

            let brokerAccount = portfolio.Accounts.get(accountName);

            if (!brokerAccount)
            {
                brokerAccount = new BrokerAccount();
                brokerAccount.Name = accountName
                portfolio.Accounts.set(accountName, brokerAccount);  
                newAccount = true;
            }

            if( xml.getAttribute("user_id" + i))
                brokerAccount.UserId = xml.getAttribute("user_id" + i) ?? ""
            if(xml.getAttribute("user_account_id" + i))
                brokerAccount.AccountId = xml.getAttribute("user_account_id" + i) ?? ""
            if (xml.getAttribute("id" + i) )
                brokerAccount.AccountNumber = xml.getAttribute("id" + i) ?? ""
     
            brokerAccount.BuyingPower = Number(xml.getAttribute("buying_power" + i)); 
            brokerAccount.AvailableFunds = Number(xml.getAttribute("available_funds" + i)); 
            brokerAccount.UnrealizedProfit = Number(xml.getAttribute("open_profit" + i)); 
            brokerAccount.RealizedProfit = Number(xml.getAttribute("closed_profit" + i)); 
            brokerAccount.Value = Number(xml.getAttribute("value" + i)); 

            if( xml.getAttribute("created" + i))
                brokerAccount.Created = new Date(xml.getAttribute("created" + i) ?? "");
        }

        const brokerAccounts = Array.from(portfolio.Accounts.values());

        let workerMessage: WorkerMessage = {
            type: WorkerMessageType.PaperResponse,
            function : WorkerMessageFunction.BrokerAccounts,
            userName: this.userConnection.user.username,
            windowId: "",
            message: [brokerAccounts]
          }

          for (const [registeredWindowKey, registeredWindow] of this.userConnection.registeredWindows.entries()) {
            workerMessage.windowId = registeredWindowKey;
            this.sendMessage(registeredWindow, workerMessage);
          };
    }

    private async AccountUpdateResponse(xml:Element)
    {
        let newAccount = false;

        const portfolio = this.brokerPortfolios.get("Paper");
        
        if(!portfolio) return;

        let accountName = xml.getAttribute("name");
        if( accountName === "Simulated Trading Default"){
            accountName = "TI Paper Trading";
        }
        if(!accountName) return;

        let brokerAccount = portfolio.Accounts.get(accountName);

        if (!brokerAccount)
        {
            brokerAccount = new BrokerAccount();
            brokerAccount.Name = accountName;
            portfolio.Accounts.set(accountName, brokerAccount); 
            newAccount = true; 
        }
        if( xml.getAttribute("user_id"))
            brokerAccount.UserId = xml.getAttribute("user_id") ?? ""
        if(xml.getAttribute("user_account_id"))
            brokerAccount.AccountId = xml.getAttribute("user_account_id") ?? ""
        if (xml.getAttribute("id") )
            brokerAccount.AccountNumber = xml.getAttribute("id") ?? ""

        brokerAccount.BuyingPower = Number(xml.getAttribute("buying_power")); 
        brokerAccount.AvailableFunds = Number(xml.getAttribute("available_funds")); 
        brokerAccount.UnrealizedProfit = Number(xml.getAttribute("open_profit")); 
        brokerAccount.RealizedProfit = Number(xml.getAttribute("closed_profit")); 
        brokerAccount.Value = Number(xml.getAttribute("value")); 
        brokerAccount.UnrealizedProfit = Number(xml.getAttribute("open_profit")); 
    
        if(xml.getAttribute("created"))
            brokerAccount.Created =  decodeServerTime(xml.getAttribute("created") ?? undefined)

        const brokerAccounts = Array.from(portfolio.Accounts.values());

        let workerMessage: WorkerMessage = {
            type: WorkerMessageType.PaperResponse,
            function : newAccount ? WorkerMessageFunction.BrokerAccountAdd : WorkerMessageFunction.BrokerAccountUpdate,
            userName: this.userConnection.user.username,
            windowId: "",
            message: [brokerAccounts,brokerAccount]
          }

          for (const [registeredWindowKey, registeredWindow] of this.userConnection.registeredWindows.entries()) {
            workerMessage.windowId = registeredWindowKey;
            this.sendMessage(registeredWindow, workerMessage);
          };
    }

    private sendMessage(registeredWindow:RegisteredWindow, message:WorkerMessage) :void
    {

      //This is the Market Date Window  
      if( registeredWindow.callback != null) return;  

      if(registeredWindow.port)
         //This is a SharedWorker Client 
         registeredWindow.port.postMessage(message);
      else
         //This is a regular Worker Client
         window.postMessage(message);
    }

    private recalculate(){

        if(!this.instrumentsInitialized){
            if(!this.allInstrumentsInitialized()){
                return;
            } 
            this.instrumentsInitialized = true;
        }

        const portfolio = this.brokerPortfolios.get("Paper");

        if(!portfolio) return;

        const now = new Date();
        now.setSeconds(0);
        now.setMilliseconds(0);

        //Reset Account Profit Values 
        for (const brokerAccount of portfolio.Accounts.values()) {
            
            brokerAccount.TodayProfit = 0;
            brokerAccount.TodayProfitPercent = 0;
            brokerAccount.UnrealizedProfit = 0;
            if( this.profitBarsInitialized)
            {
                if(brokerAccount.ProfitBars.length > 0 &&
                this.formatDatetoMinutes(brokerAccount.ProfitBars[brokerAccount.ProfitBars?.length - 1].StartDate) != 
                this.formatDatetoMinutes(now)){
                    let profitBar = new ProfitBar(now, 0, brokerAccount.TodayProfit, 0);
                    brokerAccount.ProfitBars.push(profitBar);
                } 
            }
        }

        //Recalculate Position Data 
        for (let brokerPosition of portfolio.Positions.values()) {
        
            let instrument =  this.instruments.get(brokerPosition.Symbol);
            
            if(!instrument) {
                continue;
            }

            brokerPosition.iLast = instrument.Last;         
            brokerPosition.iAsk = instrument.Ask;
            brokerPosition.iAskSize = instrument.AskSize;
            brokerPosition.iBid = instrument.Bid;
            brokerPosition.iBidSize = instrument.BidSize;
            brokerPosition.iReportingExchange = instrument.ReportingExchange;
            brokerPosition.iVolume = instrument.TotalVolume;
            brokerPosition.iTradeSize = instrument.TradeSize;

            brokerPosition.iLastClose = instrument.LastClose ?? 0;            
            brokerPosition.iChangeFromCloseDollar  = instrument.ChangeFromCloseDollar;
            brokerPosition.iChangeFromClosePercent = instrument.ChangeFromClosePercent;

            if (brokerPosition.Shares > 0)
            {
                brokerPosition.UnrealizedProfitLoss = (brokerPosition.iLast - (brokerPosition.AverageCost ?? 0)) * brokerPosition.Shares;
            }
            else
            {
                brokerPosition.UnrealizedProfitLoss = ((brokerPosition.AverageCost ?? 0) - brokerPosition.iLast) * brokerPosition.Shares;
            }

            brokerPosition.TotalProfit = (brokerPosition.UnrealizedProfitLoss ?? 0) + (brokerPosition.RealizedProfitLoss ?? 0)

            const brokerAccount = this.getBrokerAccountByAccountId(brokerPosition.AccountId, portfolio.Accounts.values());
            
            if(!brokerAccount || !brokerPosition.iLast) {
               continue;
            }

            brokerAccount.UnrealizedProfit += brokerPosition.UnrealizedProfitLoss ?? 0;

            //Not sure why this is necessary since we've already set the values to 0's in the account reset loop above
            if( brokerAccount.TodayProfit == undefined)
                brokerAccount.TodayProfit = 0;
    
            if( brokerAccount.TodayProfitPercent == undefined)
                brokerAccount.TodayProfitPercent = 0;
    
            //Calculate Position Plus/Minus

            if( brokerPosition.Shares < 0 && brokerPosition.iLast > 0) {
                brokerPosition.PositionPlusMinus = ((brokerPosition.AverageCost ?? 0)  - brokerPosition.iLast)
            }
            else if (brokerPosition.Shares > 0 && brokerPosition.iLast > 0){
                brokerPosition.PositionPlusMinus = ( brokerPosition.iLast - (brokerPosition.AverageCost ?? 0))
            }
            else{
                brokerPosition.PositionPlusMinus = 0;
            }

            brokerPosition.PositionPlusMinusPercent =  (brokerPosition.AverageCost ?? 0) > 0 ? 100.0 *  (brokerPosition.PositionPlusMinus / (brokerPosition.AverageCost ?? 0)) : 0;

            //Calculate Total Profit %
            if( brokerAccount.Value - brokerPosition.TotalProfit == 0)
                brokerPosition.TotalProfitPercent = 0.0;
            else
                brokerPosition.TotalProfitPercent = 100 * (brokerPosition.TotalProfit / ( brokerAccount.Value - brokerPosition.TotalProfit));

            const sharesHeldOvernight = brokerPosition.Shares - brokerPosition.NetSharesTradedToday;
            
            const overnightNet = sharesHeldOvernight * brokerPosition.iLastClose;
            
            if( brokerPosition.NetSharesTradedToday + sharesHeldOvernight == 0)
                brokerPosition.TodayAveragePrice = 0; 
            else
                brokerPosition.TodayAveragePrice = (brokerPosition.NetMoneyTradedToday + overnightNet) / (brokerPosition.NetSharesTradedToday + sharesHeldOvernight); 
         

            //Calculate TodayProfit
            brokerPosition.TodayProfit = 0;
            if( brokerPosition.TodayAveragePrice > 0){
                
                brokerPosition.TodayProfit = ((brokerPosition.iLast - brokerPosition.TodayAveragePrice) * brokerPosition.Shares) - brokerPosition.Commissions;  
           
            } 
            else if( brokerPosition.Shares == 0) {

                if( brokerPosition.NetSharesTradedToday != 0)
                    brokerPosition.TodayProfit = (brokerPosition.iLastClose * brokerPosition.NetSharesTradedToday) - brokerPosition.NetMoneyTradedToday - brokerPosition.Commissions; 
                else
                    brokerPosition.TodayProfit = -brokerPosition.NetMoneyTradedToday - brokerPosition.Commissions;        
            }

            //Calculate Today Profit %
            brokerPosition.TodayProfitPercent = 0;
            if (brokerAccount.Value - brokerPosition.TodayProfit != 0)
                brokerPosition.TodayProfitPercent = 100 * (brokerPosition.TodayProfit / ( brokerAccount.Value - brokerPosition.TodayProfit));                

            brokerAccount.TodayProfit += brokerPosition.TodayProfit;
            brokerAccount.TodayProfitPercent += brokerPosition.TodayProfitPercent;
   
            //Check if we need to add a new ProfitBar    
            // const now = new Date();
            // now.setSeconds(0);
            if( this.profitBarsInitialized)
            {

                if (
                    //Check for the next minute
                    (brokerPosition.ProfitBars.length > 0 &&
                     this.formatDatetoMinutes(brokerPosition.ProfitBars[brokerPosition.ProfitBars?.length - 1].StartDate) !=
                     this.formatDatetoMinutes(now))        ||
                     //Or a new position 
                     (brokerPosition.ProfitBars.length === 0 &&
                     brokerPosition.Created != this.formatDatetoMinutes(now))
                ) {
                    let profitBar = new ProfitBar(now, brokerPosition.Shares, brokerPosition.TodayProfit, brokerPosition.iLast);
                    brokerPosition.ProfitBars.push(profitBar);

                    brokerAccount.ProfitBars[brokerAccount.ProfitBars.length - 1].Profit += brokerPosition.TodayProfit;

                }
            }
        }

        //Initialize Historical Profit Bars One-Time 
        if(!this.profitBarsInitialized){

            this.profitBarsInitialized = this.addHistoricalBars(portfolio);
            
            if(this.profitBarsInitialized){
               clearInterval(this.recalculateIntervalId);
               //Reset the Recalculate Interval after we have all data initialized     
               this.recalculateIntervalId = self.setInterval(this.recalculate.bind(this), 2000) as number;

               let workerMessage: WorkerMessage = {
                type: WorkerMessageType.PaperResponse,
                function :  WorkerMessageFunction.BrokerSymbolBars,
                userName: this.userConnection.user.username,
                windowId: "",
                message: [this.symbolBars]
              }
    
              for (const [registeredWindowKey, registeredWindow] of this.userConnection.registeredWindows.entries()) {
                workerMessage.windowId = registeredWindowKey;
                this.sendMessage(registeredWindow, workerMessage);
              };
            }
        }

        if(this.profitBarsInitialized){
                    
            const brokerPositions = Array.from(portfolio.Positions.values());

            let workerMessage: WorkerMessage = {
                type: WorkerMessageType.PaperResponse,
                function :  WorkerMessageFunction.BrokerPositionsUpdate,
                userName: this.userConnection.user.username,
                windowId: "",
                message: [brokerPositions, brokerPositions]
            }
    
            for (const [registeredWindowKey, registeredWindow] of this.userConnection.registeredWindows.entries()) {
                workerMessage.windowId = registeredWindowKey;
                this.sendMessage(registeredWindow, workerMessage);
            };

            const brokerAccounts = Array.from(portfolio.Accounts.values());
            //Reassign workerMessage
            workerMessage = {
                type: WorkerMessageType.PaperResponse,
                function :  WorkerMessageFunction.BrokerAccountsUpdate,
                userName: this.userConnection.user.username,
                windowId: "",
                message: [brokerAccounts, brokerAccounts]
            }

            for (const [registeredWindowKey, registeredWindow] of this.userConnection.registeredWindows.entries()) {
                workerMessage.windowId = registeredWindowKey;
                this.sendMessage(registeredWindow, workerMessage);
            };
          
        }
    }

    private addHistoricalBars(portfolio:BrokerPortfolio){

        if( !this.symbolBars)
        {
         return false;
        }

        for (let brokerPosition of portfolio.Positions.values()) {
           
            const brokerAccount = this.getBrokerAccountByAccountId(brokerPosition.AccountId, portfolio.Accounts.values());
            
            let instrument =  this.instruments.get(brokerPosition.Symbol);
            if(!instrument){
               continue;
            } 

            let symbolGrid = this.symbolBars.get(brokerPosition.Symbol)
            if(!symbolGrid) {
              continue;
            }

            //Remove the header item and empty [''] after reversing 
            symbolGrid.grid.shift();
            symbolGrid.grid.pop();
            
            const barToCheckTime = decodeServerTime(symbolGrid.grid[0][2]);
    
            if( barToCheckTime?.getDate() != new Date().getDate())
                symbolGrid.grid.shift();

            let symbolBars = symbolGrid.grid.reverse();
            let sharesTradedToday = brokerPosition.NetSharesTradedToday;
            let todayNet = brokerPosition.NetMoneyTradedToday;
            let sharesHeldOvernight = brokerPosition.Shares - sharesTradedToday;
            let overnightNet = sharesHeldOvernight * instrument.LastClose;
            let currentCommissions = brokerPosition.Commissions;
            let currentAveragePrice = brokerPosition.TodayAveragePrice;
            let currentShares = brokerPosition.Shares;
            let currentProfit = brokerPosition.TodayProfit;
            let profit = 0;
            let lastProfit = currentProfit;
            let executionsByTime = this.getExecutionsByTimeForAccountAndSymbol(portfolio,brokerPosition.AccountId, brokerPosition.Symbol);

            for (const bar of symbolBars) {

                const barTime = decodeServerTime(bar[2]);
                
                let exitNowPrice =  parseFloat(bar[4]?.trim() ?? "0");

                //Check if we have a bad data from from Single Grid Response 

                let minutePositionCreated = brokerPosition.Created ?? new Date();

                minutePositionCreated.setSeconds(0);
                if(isNaN(exitNowPrice) || !barTime || ( barTime < minutePositionCreated)) {
                    continue;
                }

                let timeString = this.formatDatetoMinutes(barTime)
                
                if(brokerPosition.iLastClose == 0){
                    profit = 0;
                }
                else{

                    if( currentAveragePrice > 0){
                                                                        
                        if( exitNowPrice == 0)
                            exitNowPrice = brokerPosition.iLastClose ?? 0;

                        profit = ((exitNowPrice - currentAveragePrice) * currentShares) - currentCommissions;  

                    }else if(currentShares == 0){
                        profit = lastProfit;
                    }

                    if( currentAveragePrice > 0 && currentShares == 0){
                        profit += currentCommissions;
                    }
                }

                profit = Number(profit.toFixed(4));

                lastProfit = profit;

                brokerPosition.ProfitBars?.splice(0, 0, new ProfitBar(barTime, currentShares, profit, exitNowPrice));

                let accountProfitBar = brokerAccount?.ProfitBars.find(( pb) => pb.StartDate?.getTime() === barTime.getTime() )
                if( !accountProfitBar)
                {
                    accountProfitBar = new ProfitBar(barTime,0,0,0);
                    brokerAccount?.ProfitBars.splice(0,0, new ProfitBar(barTime,0,profit,0));
                }
                accountProfitBar.Profit += profit;

                if(timeString ){
                    let executions = executionsByTime.get(timeString);
                    if( executions){
                        for (const execution of executions ) {
                            let  executionMoney = execution.Shares * execution.FilledPrice;
                            if( execution.Side === "Buy" ){
                                currentShares = currentShares - execution.Shares;
                                todayNet = todayNet - executionMoney;
                                sharesTradedToday = sharesTradedToday - execution.Shares;

                            } else{
                                currentShares = currentShares + execution.Shares;
                                todayNet = todayNet + executionMoney;
                                sharesTradedToday = sharesTradedToday + execution.Shares;
                            }
                            if (sharesTradedToday + sharesHeldOvernight == 0)
                                currentAveragePrice = 0;
                            else
                                currentAveragePrice = (todayNet + overnightNet) / (sharesTradedToday + sharesHeldOvernight);
                    
                            currentCommissions -= execution.Commissions;
                        }
                    }
                }
            }
        }
        
        return true;
    }

    private getExecutionsByTimeForAccountAndSymbol(portfolio: BrokerPortfolio, accountId:string, symbol:string): Map<string, Array<BrokerExecution>> {

        let brokerExectionsByTime = new Map<string, Array<BrokerExecution>>();

        for (const brokerExecution of portfolio.Executions.values()) {

            if( brokerExecution.AccountId === accountId && brokerExecution.Symbol === symbol  ){

                const timeString = this.formatDatetoMinutes(brokerExecution.Filled)
                if(timeString){
                    if(!brokerExectionsByTime.has(timeString)){
                        brokerExectionsByTime.set(timeString, new Array<BrokerExecution>())    
                    }
                    brokerExectionsByTime.get(timeString)?.unshift(brokerExecution);
                }
            } 
        }
        return brokerExectionsByTime;
    }

    private allInstrumentsInitialized(){

        let nowTime = new Date().getTime();

        for (const instrument of this.instruments.values()) {
            if( instrument.LastClose === 0 && ((nowTime - instrument.Created.getTime()) < 2000) ) return false;
        }
        return true;
    }

    private async getBrokerPosition(accountId:string, symbol:string, portfolio:BrokerPortfolio): Promise<{ added: boolean; position: BrokerPosition; }> {

        /**
     * This method checks for an existing brokerPostion
     * using the key of accountId + "_" + symbol 
     * If a position does not exist one is created and added to 
     * the positions map 
     * 
     *  
    */
        let position = portfolio.Positions.get(accountId + "_" + symbol);
        let added = false;

        if (!position)
        {
           position = new BrokerPosition();
           position.Symbol = symbol;
           position.Key = accountId + "_" + symbol;
           portfolio.Positions.set(position.Key, position);  
           added = true;

           let brokerInstrument = this.instruments.get(symbol);
           let pauses = 0;
           if( !brokerInstrument)
           {
                this.subscribeMarketData(symbol);
                do {
                  pauses++;  
                  await this.sleep(25);       
                  brokerInstrument = this.instruments.get(symbol);
                } while (!brokerInstrument &&  pauses < 40);  
            } 
        }

        return {added, position};
    }
    
    private  sleep(ms:number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    private getItemByKey(key: string, maps:Map<string, string>[]): string | undefined {
        
        for (let map of maps) {
          if (map.has(key)) {
              return map.get(key);
          }
        }
        return undefined; 
    }

    private getBrokerAccountByAccountId(accountId: string, brokerAccounts:IterableIterator<BrokerAccount>): BrokerAccount | undefined {
        
        for (let brokerAccount of brokerAccounts) {
            if (brokerAccount.AccountId == accountId) {
                return brokerAccount;
            }
        }
        return undefined; 
    }

    private formatDatetoMinutes(date:Date | undefined) {

        if (!date) return undefined;
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }

    private getBars(brokerPositions:Array<BrokerPosition>){

        var symbols = brokerPositions.map(position => position.Symbol).join(" ");

        const requestModel = new ProfitBackfillRequestModel(); 

        requestModel.symbols = symbols;

        const profitBackfillClient = new ProfitBackfillClient(this.userConnection.connectionMaster);

        profitBackfillClient.getProfitBackfill(requestModel).then((response:ClientResponseModel<Map<string, SingleGridResponse>>) => {
            if(response.data)
               this.symbolBars = response.data;
        });
    }

    private registeredWindowFromConnection(connection: UserConnection, windowId: string): RegisteredWindow | undefined {
    
        if(!connection)
          return undefined
        else
          return connection.registeredWindows.get(windowId);
    
    }
}


// private topListResponseReceived(response:ResponseFromServer){
        
    //     const responseAsString = responseToString(response);

    //     const xml = parseXml2(responseAsString);
    
    //     if(!xml)
    //         return;
        
    //     const topList =  xml?.getElementsByTagName("TOPLIST")[0];

    //     const node = topList.getElementsByTagName("NODE")[0];

    //     const type = topList.getAttribute("TYPE") ?? "";

    //     if( type === "data"){

    //         const symbol = node.getAttribute("_2") ?? "";
    //         const lastPrice = node.getAttribute("_k") ?? "";
    //         const changeFromCloseDollar = node.getAttribute("_m") ?? "";
    //         const changeFromClosePercent = node.getAttribute("_n") ?? "";

    //         let instrument =  this.instruments.get(symbol);

    //         if(instrument){

    //             instrument.Last = parseFloat(lastPrice?.trim() ?? "0");
    //             instrument.ChangeFromCloseDollar  = parseFloat(changeFromCloseDollar?.trim() ?? "0");
    //             instrument.ChangeFromClosePercent = parseFloat(changeFromClosePercent?.trim() ?? "0");
    //             instrument.LastClose = instrument.Last - instrument.ChangeFromCloseDollar;

    //         }

    //     }

    // }