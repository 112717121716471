export class TradeWaveResponseModel {
    public symbol = '';
    public canEnter = false;
    public canExit = false;
    public close: number | null = null;
    public fastValue: number | null = null;
    public slowValue: number | null = null;
    public differenceAboveTopPercent: number | null = null;
    public differenceBetweenBands: number | null = null;
    public waveColor = '';
    public isValid = false;
    public message = '';
}