import { DateTime } from "luxon";
import { MarketSchedule, SettingsClient } from "../../settings.client";
import { HolidayCountry } from "../enums/holiday-country.enum";
import { DateHelpers } from "../helpers/date-helpers";
import { HolidayModel } from "./holiday.model";

export class HolidayManager {

    private holidays: Array<HolidayModel> = [];

    private holidaysDict: Record<string, Record<HolidayCountry, HolidayModel>> = {};

    private readonly dateStorageFormat = "yyyy-mm-dd";

    public marketSchedule = new MarketSchedule();
    public marketScheduleLoaded = false;

    private static instance: HolidayManager | null = null;

    constructor() { }

    public static getInstance(): HolidayManager {
        if (this.instance == null) {
            this.instance = new HolidayManager();
        }

        return this.instance;
    }

    public static setHolidays(holidaysData: Array<HolidayModel>) {
        if (!holidaysData) {
            return;
        }

        const instance = this.getInstance();
        instance.holidays = holidaysData;

        for (const holiday of instance.holidays) {
            if (!holiday.date) {
                continue;
            }

            const ymd = DateHelpers.format(instance.dateStorageFormat, holiday.date);

            let holidayForCountry: Record<HolidayCountry, HolidayModel> = {} as any;

            if (instance.holidaysDict[ymd]) {
                holidayForCountry = instance.holidaysDict[ymd];
            }

            holidayForCountry[holiday.country] = holiday;
            instance.holidaysDict[ymd] = holidayForCountry;
        }
    }

    public static initMarketSchedule(markets: string): Promise<void> {
        const instance = this.getInstance();
        return new Promise<void>((resolve, reject) => {
            if (instance.marketScheduleLoaded) {
                resolve();
            } else {
                instance.marketScheduleLoaded = true;
                SettingsClient.getInstance().getMarketSchedule(markets).then((res: Array<MarketSchedule>) => {
                    instance.marketSchedule = res?.[0] ?? new MarketSchedule();
                    resolve();
                }).catch(() => {
                    reject();
                });
            }
        });
    }

    public getMarketSchedule(): MarketSchedule {
        return this.marketSchedule;
    }

    public isPreMarketDisabled(): boolean {
        return this.marketSchedule.preMarketMinutes == 0;
    }

    public isPostMarketDisabled(): boolean {
        return this.marketSchedule.postMarketMinutes == 0;
    }
    
    public getHolidayCountry(exchangeCode: string): HolidayCountry {
        if (exchangeCode == "CAT" || exchangeCode == "CAV")
            return HolidayCountry.Canada;
        return HolidayCountry.US;
    }

    public isHalfDayWithReferenceDate(referenceDate?: Date): boolean {
        if (!referenceDate)
            referenceDate = new Date();

        const country = HolidayCountry.US;
        return this.isHalfDayWithCountry(country, referenceDate);
    }

    public isHalfDayWithExchangeCode(exchangeCode: string, date: Date): boolean {
        const country = this.getHolidayCountry(exchangeCode);
        return this.isHalfDayWithCountry(country, date);
    }

    public isHalfDayWithCountry(country: HolidayCountry, date: Date): boolean {
        const ymd = DateHelpers.format(this.dateStorageFormat, date);
        if (this.holidaysDict[ymd] && this.holidaysDict[ymd][country])
            return this.holidaysDict[ymd][country].halfDay;

        return false;
    }

    public isHolidayMarketClosed(referenceDate?: Date): boolean {
        if (!referenceDate) {
            referenceDate = new Date();
        }
        const country = HolidayCountry.US;
        return this.isHolidayMarketClosedWithCountry(country, referenceDate);
    }

    public isHolidayMarketClosedWithExchangeCode(exchangeCode: string, date: Date): boolean {
        const country = this.getHolidayCountry(exchangeCode);
        return this.isHolidayMarketClosedWithCountry(country, date);
    }

    public isHolidayMarketClosedWithCountry(country: HolidayCountry, date: Date): boolean {
        const ymd = DateHelpers.format(this.dateStorageFormat, date);
        if (this.holidaysDict[ymd] && this.holidaysDict[ymd][country])
            return this.holidaysDict[ymd][country].closed;

        return false;
    }

    public isMarketClosed(referenceDate?: Date): boolean {
        if (!referenceDate) {
            referenceDate = new Date();
        }

        return this.isHolidayMarketClosed(referenceDate) || DateHelpers.isWeekend(referenceDate);
    }

    public getMarketOpenInMarketTimeZone(date: Date): DateTime {
        let dateInMarketTimeZone = DateHelpers.toMarketTimeZone(date);

        // TraceLoggingHelpers.log('dateInMarketTimeZone ', dateInMarketTimeZone)
        // Find next marketday.  Take into account weekends and holidays.
        dateInMarketTimeZone = DateHelpers.getNextWeekday(dateInMarketTimeZone);

        const holidayManager = HolidayManager.getInstance();
        while (holidayManager.isHolidayMarketClosed(dateInMarketTimeZone.toJSDate())) {
            // Find next marketday.  Take into account weekends and holidays.
            dateInMarketTimeZone = DateHelpers.getNextWeekday(dateInMarketTimeZone);
            dateInMarketTimeZone = DateHelpers.addWeekDays(dateInMarketTimeZone, 1);
        }

        const marketOpenMarketTimeZone = dateInMarketTimeZone.startOf('day').plus({ minute: this.marketSchedule.minutesUntilOpen });
        // TraceLoggingHelpers.log('marketOpenMarketTimeZone ', marketOpenMarketTimeZone)
        return marketOpenMarketTimeZone;
    }

    public getMarketCloseInMarketTimeZone(date: Date): DateTime {
        let dateInMarketTimeZone = DateHelpers.toMarketTimeZone(date);
        let minutesTillTodaysClose = this.marketSchedule.minutesUntilOpen + this.marketSchedule.fullDayOpenMinutes;

        const holidayManager = HolidayManager.getInstance();
        if (holidayManager.isHalfDayWithReferenceDate(date)) {
            minutesTillTodaysClose = this.marketSchedule.minutesUntilOpen + this.marketSchedule.halfDayOpenMinutes;
        }

        dateInMarketTimeZone = dateInMarketTimeZone.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        const marketCloseMarketTimeZone = dateInMarketTimeZone.plus({ minutes: minutesTillTodaysClose });
        return marketCloseMarketTimeZone;
    }

    public getPreMarketOpenTime(): DateTime {
        const marketTimeZoneNow = DateHelpers.getNowInMarketTimeZone();
        
        const minutesPreMarketOpen = this.marketSchedule.minutesUntilOpen - this.marketSchedule.preMarketMinutes;
        return marketTimeZoneNow.startOf('day').plus({ minute: minutesPreMarketOpen });
    }

    public getMarketOpenTime(): DateTime {
        const marketTimeZoneNow = DateHelpers.getNowInMarketTimeZone();
        return marketTimeZoneNow.startOf('day').plus({ minute: this.marketSchedule.minutesUntilOpen });
    }

    public getPostMarketMinutes() {
        return this.marketSchedule.postMarketMinutes;
    }

    public getPreMarketMinutes() {
        // Modified to inclue all pre-market minutes if it's currently pre-market.
        const now = DateHelpers.getNowInMarketTimeZone();
        const marketOpen = this.getMarketOpenInMarketTimeZone(now.toJSDate());
        if (now.valueOf() <= marketOpen.valueOf())
            return this.marketSchedule.preMarketMinutes; // 5.5 hours.
        else
            return 0;
    }
}