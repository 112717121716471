import { Connection } from "./connection.client";
import { ChartDataToTradeWaveDataPointModelMapper } from "./mappers/chart-data-to-trade-wave-data-point-model.mapper";
import { ChartDataRequestModel } from "./models/chart-data/chart-data-request.model";
import { ClientResponseModel } from "./models/client-response/client-response.model";
import { IdGenerator } from "./models/helpers/id-generator";
import { TimeframeHelpers } from "./models/helpers/timeframe-helpers";
import { TradeWaveDataPoint } from "./models/trade-waves/trade-wave-data-point.model";
import { TradeWaveRequestModel } from "./models/trade-waves/trade-wave-request.model";
import { TradeWaveResponseModel } from "./models/trade-waves/trade-wave-response.model";
import { TradeWaveSettingsModel } from "./models/trade-waves/trade-wave-settings.model";

export class TradeWaveClient {

    private id = IdGenerator.NewId();

    private readonly preMarketMinutes = 330;
    private readonly postMarketMinutes = 300;

    private tradeWave = new TradeWaveSettingsModel();

    constructor() { }

    public async getSignals(requestModel: TradeWaveRequestModel): Promise<ClientResponseModel<TradeWaveResponseModel>> {
        const response = new ClientResponseModel<TradeWaveResponseModel>();
        const dataPoints = await this.getDataPoints(requestModel);
        if (!dataPoints?.length) {
            response.message = 'No datapoints found!';
            return response;
        }

        const isLong = requestModel.direction?.toLowerCase() === 'long';

        const responseModel = new TradeWaveResponseModel();
        const currentBar = dataPoints[dataPoints.length - 1];
        this.tradeWave.setCurrentBar(currentBar);

        const close = currentBar.close;
        const fastValue = this.tradeWave.getFastValue(requestModel.cloudNumber);
        const slowValue = this.tradeWave.getSlowValue(requestModel.cloudNumber);
        if (fastValue == null || isNaN(fastValue) || slowValue == null || isNaN(slowValue)) {
            response.message = 'No fast value or slow value found!';
            return response;
        }
        
        responseModel.isValid = true;
        
        const isBullish = fastValue > slowValue;

        let useDifferenceAboveTopCheck = requestModel.differenceAboveTop != null && requestModel.differenceAboveTop != undefined && !isNaN(requestModel.differenceAboveTop);
        let requestDifferenceAboveTop = 0;
        let actualPercentageDifference = 0;
        if (useDifferenceAboveTopCheck) {
            const entryDifferenceAboveValue = isLong ? fastValue : slowValue;
            actualPercentageDifference = ((close - entryDifferenceAboveValue) / entryDifferenceAboveValue) * 100;
            requestDifferenceAboveTop = Math.abs(requestModel.differenceAboveTop ?? 0);
            responseModel.differenceAboveTopPercent = Math.abs(actualPercentageDifference);
        }

        let useDifferenceBetweenBandsCheck = requestModel.differenceBetweenBands != null && requestModel.differenceBetweenBands != undefined && !isNaN(requestModel.differenceBetweenBands);
        let requestDifferenceBetweenBands = 0;
        let actualPercentageDifferenceBetweenTopBottom = 0;
        if (useDifferenceBetweenBandsCheck) {
            actualPercentageDifferenceBetweenTopBottom = fastValue - slowValue;
            requestDifferenceBetweenBands = Math.abs(requestModel.differenceBetweenBands ?? 0);
            responseModel.differenceBetweenBands = Math.abs(actualPercentageDifferenceBetweenTopBottom);
        }

        const shouldUseEntryCloseCheck = requestModel.entryCloseAboveTop || requestModel.entryCloseAboveBottom;
        const shouldUseExitCloseCheck = requestModel.exitCloseBelowTop || requestModel.exitCloseBelowBottom;
        if (shouldUseEntryCloseCheck || shouldUseExitCloseCheck) {
            if (isLong) {
                const entryCloseValueToCheck = requestModel.entryCloseAboveTop ? fastValue : slowValue;
                const exitCloseValueToCheck = requestModel.exitCloseBelowTop ? fastValue : slowValue;
                if (shouldUseEntryCloseCheck) {
                    responseModel.canEnter = fastValue > slowValue &&
                        close > entryCloseValueToCheck &&
                        (!useDifferenceAboveTopCheck || actualPercentageDifference <= requestDifferenceAboveTop) &&
                        (!useDifferenceBetweenBandsCheck || actualPercentageDifferenceBetweenTopBottom > requestDifferenceBetweenBands);
                } else {
                    responseModel.canEnter = fastValue > slowValue;
                }

                if (shouldUseExitCloseCheck) {
                    responseModel.canExit = close < exitCloseValueToCheck || fastValue < slowValue;
                } else {
                    responseModel.canExit = fastValue < slowValue;
                }
            } else {
                const entryCloseValueToCheck = requestModel.entryCloseAboveTop ? slowValue : fastValue;
                const exitCloseValueToCheck = requestModel.exitCloseBelowTop ? slowValue : fastValue;
                if (entryCloseValueToCheck) {
                    responseModel.canEnter = fastValue < slowValue &&
                        close < entryCloseValueToCheck && 
                        (!useDifferenceAboveTopCheck || actualPercentageDifference <= requestDifferenceAboveTop) &&
                        (!useDifferenceBetweenBandsCheck || actualPercentageDifferenceBetweenTopBottom > requestDifferenceBetweenBands);
                } else {
                    responseModel.canEnter = fastValue < slowValue;
                }

                if (shouldUseExitCloseCheck) {
                    responseModel.canExit = close > exitCloseValueToCheck || fastValue > slowValue;
                } else {
                    responseModel.canExit = fastValue > slowValue;
                }
            }
        } else {
            if (isLong) {
                responseModel.canEnter = fastValue > slowValue &&
                    (!useDifferenceAboveTopCheck || actualPercentageDifference <= requestDifferenceAboveTop) &&
                    (!useDifferenceBetweenBandsCheck || actualPercentageDifferenceBetweenTopBottom > requestDifferenceBetweenBands);
                responseModel.canExit = fastValue < slowValue;
            } else {
                responseModel.canEnter = fastValue < slowValue &&
                    (!useDifferenceAboveTopCheck || actualPercentageDifference <= requestDifferenceAboveTop) &&
                    (!useDifferenceBetweenBandsCheck || actualPercentageDifferenceBetweenTopBottom > requestDifferenceBetweenBands);
                responseModel.canExit = fastValue > slowValue;
            }
        }
        
        responseModel.waveColor = isBullish ? 'blue' : 'orange'
        responseModel.symbol = requestModel.symbol;
        responseModel.close = close;
        responseModel.fastValue = fastValue;
        responseModel.slowValue = slowValue;

        if (!requestModel.entryCloseAboveTop && requestModel.exitCloseBelowTop) {
            responseModel.message = `If entryCloseAboveTop is = false, exitCloseBelowTop must also be false or it will return canEnter = true and canExit = true at the same time, causing an invalid state`;
            responseModel.isValid = false;
            responseModel.canEnter = false;
            responseModel.canExit = false;
        }

        response.isSuccess = true;
        response.data = responseModel;
        return response;
    }

    private async getDataPoints(requestModel: TradeWaveRequestModel): Promise<Array<TradeWaveDataPoint>> {
        const chartDataRequest = new ChartDataRequestModel();
        chartDataRequest.extraRowCount = 1;
        chartDataRequest.symbols = [requestModel.symbol.toUpperCase()];

        chartDataRequest.rowCount = 2;
        const timeframe = TimeframeHelpers.toTimeframeEnum(requestModel.timeframe);
        if (TimeframeHelpers.timeframeIntraday(timeframe)) {
            const minutesPerCandle: any = TimeframeHelpers.getIntradayChartTimeFrameMinutePerCandle(timeframe);
            chartDataRequest.options = {
                timeFrame: 'intraday',
                minutesPerCandle: minutesPerCandle,
            };

            if (requestModel.preMarket) {
                chartDataRequest.options.startOffset = this.preMarketMinutes;
            }

            if (requestModel.postMarket) {
                chartDataRequest.options.endOffset = this.postMarketMinutes;
            }
        } else {
            const candleSize: any = TimeframeHelpers.getDailyChartTimeFrameCandleSize(timeframe);
            chartDataRequest.options = {
                timeFrame: 'daily',
                candleSize: candleSize
            };
        }

        for (const setting of this.tradeWave.settings) {
            const value = Math.max(setting.fastPeriod, setting.slowPeriod) * 2;
            if (value > chartDataRequest.extraRowCount) {
                chartDataRequest.extraRowCount = value;
            }

            const fastKey = setting.getFastPeriodGridRequestKey();
            if (!chartDataRequest.gridRequest.some(x => x[0] == fastKey)) {
                chartDataRequest.gridRequest.push([fastKey, []]);
            }

            const slowKey = setting.getSlowPeriodGridRequestKey();
            if (!chartDataRequest.gridRequest.some(x => x[0] == slowKey)) {
                chartDataRequest.gridRequest.push([slowKey, []]);
            }
        }

        try {
            const response = await Connection.getInstance().chartDataClient.getChartData(chartDataRequest);
            if (response.data) {
                const dataPoints = ChartDataToTradeWaveDataPointModelMapper.map(response.data);
                return dataPoints;
            }
        } catch {
        }

        return [];
    }
}