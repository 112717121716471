import { ClientResponseModel } from "../lib-export/everything";
import { haltedListenRequest } from "./api-request-builders/halted-request.builder";
import { HaltedRequestModel } from "./models/halted/halted-request.model";
import { HaltedResponseModel } from "./models/halted/halted-response.model";
import { TraceLoggingHelpers } from "./models/helpers/trace-logging-helpers";
import { AppSettings } from "../app-settings";
import {  Connection  } from "./connection.client";
import { ThrottledMarketDataClient, TradeQuote, TradeQuoteCallback } from "./throttled-market-data.client";
import axios, { AxiosResponse, AxiosError } from 'axios';

interface HaltResumeItem {
    id: number; // Example: Define the actual properties of the item
    time: string; // The time property as a UTC string
    // Add other properties as needed
}

interface HaltResumeResponse {
    Obj: HaltResumeItem[];
}
export class HaltedClient {
    

    private haltedUnsubscribeWithCancelTokenCallback: any = null;
    
    private _haltResumedCallbacks: HaltResumedCallback[] = [];

    private _marketDataClient!:ThrottledMarketDataClient;
    private _haltResumeSubscribedSymbol: string[] = []
    constructor() {
    }
    public subscribe(requestModel: HaltedRequestModel, setResponseOnDataCallback: Function): HaltedClient {
        this.unsubscribe();

        if (requestModel.symbol) {
            requestModel.symbol = requestModel.symbol.toUpperCase();
        }

        this.haltedUnsubscribeWithCancelTokenCallback = haltedListenRequest(requestModel, (responseXML: Element | null) => {
            const model = new ClientResponseModel<HaltedResponseModel>();
            if (!responseXML) {
                setResponseOnDataCallback(model);
                return;
            }
    
            // <API halted_since="0" reason="" status="0" symbol="" type="halt_status"/>
            const responseModel = new HaltedResponseModel();
            const node = responseXML;
            responseModel.status = parseInt(node.getAttribute('status') ?? '');
            responseModel.reason = node.getAttribute('reason') ?? '';
            responseModel.showNotification = responseModel.status == 1;

            model.isSuccess = true;
            model.data = responseModel;
            
            setResponseOnDataCallback(model);
        });

        return this;
    }
    public unsubscribe() {
        TraceLoggingHelpers.log('unsubscribe ', this.haltedUnsubscribeWithCancelTokenCallback)
        if (this.haltedUnsubscribeWithCancelTokenCallback) {
            this.haltedUnsubscribeWithCancelTokenCallback();
            this.haltedUnsubscribeWithCancelTokenCallback = null;
        }
    }
    public setResumeCallback(haltResumedCallback :HaltResumedCallback ){
        this._haltResumedCallbacks.push(haltResumedCallback);
    }
    public getHaltsResumes(symbols:string, halts:boolean, resumes:boolean, notifyOnResume:boolean): Promise<any> {
        return new Promise<any>((resolve,reject) => {
                 

                if ( !this._marketDataClient){
                    this._marketDataClient = ThrottledMarketDataClient.GetInstance();
                    this._marketDataClient.Connect();
                }


                //Using Broker Services Endpoint 
                let types = "";
 
                if(halts)
                     types = "2";
 
                if (resumes){
                    if(types.length > 0)
                       types += ",";
                    types += "0";
                }

                axios.get<any>("https://" + AppSettings.getInstance().tiServicesHost + "/TradeIdeasWebService/v1/WebSocketTunnel/halts?symbols=" + symbols + "&types=" + types)
                  .then((response: any ) => {
                    if(response.status === 200){
                        
                        response.data.Obj.forEach((halt: { type: string, symbol:string }) => {
                            if(notifyOnResume && halt.type == "Halted" && !this._haltResumeSubscribedSymbol.includes(halt.symbol)){
                                this._haltResumeSubscribedSymbol.push(halt.symbol);
                                this._marketDataClient.SubscribeTradeQuote(halt.symbol,"halted-client",false, this.tradeQuoteCallback.bind(this));
                            }
                        });
                      
                       resolve(response.data);
                     }
                    else{
                        reject(response.message);
                    }
                  })   
                  .catch((error: AxiosError) => {
                    if( error.response?.data)
                       reject(error.response?.data);
                    else
                       reject(error.message);
                  });         
        });
    }
    public getHaltResumesSubscribedSymbols(): string[]{
        return this._haltResumeSubscribedSymbol;
    }
    private tradeQuoteCallback: TradeQuoteCallback = (tradeQuote: TradeQuote) => { 
    
        
            if(!tradeQuote.HaltStatus || !tradeQuote.HaltStatusTime)
                return;

            if( tradeQuote.HaltStatus == "Resume" )
            {                
                let index = this._haltResumeSubscribedSymbol.indexOf(tradeQuote.Symbol);
                if (index !== -1) {
                    this._haltResumeSubscribedSymbol.splice(index, 1);
                  }
                this._marketDataClient.UnSubscribeTradeQuote(tradeQuote.Symbol,"halted-client");    

                this._haltResumedCallbacks.forEach(callback =>{
                    callback({symbol: tradeQuote.Symbol, type: tradeQuote.HaltStatus, time: tradeQuote.HaltStatusTime});
                });
            } 
    }
}
export type HaltResumedCallback = (arg:HaltResumed) => void;
export type HaltResumed = {
    symbol: string,
    type: string | undefined,
    time: Date| undefined
}