import {createMessageToServer, responseToString, ResponseFromServer, getCommandName, MessageToServer} from "./talk-with-server";
import {ConnectionMaster} from "./connection-master";
import {ConnectionCallbacks} from "./lifecycle-manager";
import { User } from "./login-manager";
import { TraceLoggingHelpers } from "../services/models/helpers/trace-logging-helpers";
import { parseXml2, testXml2 } from "./polyfill-DomParser";
import {PaperManagerWorker} from "./paper-manager.worker"
import { ServerCommand } from "../services/connection.client";
import { symbolListRequest } from "../services/api-request-builders/symbol-list-request.builder";

/**
 *  
 * 
 * 
 */
export interface LoginRequest {
  applicationId: number;
  applicationVersion?: string;
  destination?: string 
  traceLogging?: boolean | undefined; 
  userName: string;
  password: string;
  location:string;
}
/**
 * These message types can either be requets or responses,  
 * depending on which side of the worker they are consumed 
 * 
 */
export enum WorkerMessageType{
  Error = 0,
  Ping = 1,
  Login = 2,
  InvalidLogin = 3,
  SendWithNoResponse = 4,
  SendWithSingleResponse = 5,
  SendWithStreamingResponse = 6, 
  CancelToken = 7,
  Connected = 8,
  Disconnected = 9,
  Reconnected = 10,
  PingHealth = 11,
  DisconnectedForGood = 12,
  ConnectionStatusUpdate = 13,
  PaperRequest = 14,
  PaperResponse = 15,
  SubscribeMarketData = 16,
  UnSubscribeMarketData = 17,
  MarketDataResponse = 18
}

export enum WorkerMessageFunction{
  BrokerPortfolioRequest = 1,
  BrokerOrderSubmit = 2,
  BrokerOrderModify = 3,
  BrokerOrderCancel = 4,
  BrokerAccounts = 5,
  BrokerAccountAdd = 6,
  BrokerAccountUpdate = 7,
  BrokerAccountsUpdate = 8,
  BrokerPositions = 9,
  BrokerPositionAdd = 10,
  BrokerPositionUpdate = 11,
  BrokerPositionsUpdate = 12,
  BrokerOrders = 13,
  BrokerOrderAdd = 14,
  BrokerOrderUpdate = 15,
  BrokerExecutions = 16,
  BrokerExecutionsAdd = 17,
  BrokerSymbolBars = 18,
  BrokerPositionAddResponse = 19

}

/**
 *  This is the interface for all messages sent 
 *  between the worker and the client
 */
export interface WorkerMessage{
  type: WorkerMessageType;
  function?:  WorkerMessageFunction | undefined;
  userName: string;
  windowId: string;
  messageId? : number;
  message?: any | undefined;
  command?: string;
  listenerWindowId?: string;
}


//SharedWorker API does not provide an onclose event for a message port
//we must implement a pinging mechanism to detect when the client closes so we can unregister their streaming server commands
//https://github.com/whatwg/html/issues/1766
//https://stackoverflow.com/questions/13662089/javascript-how-to-know-if-a-connection-with-a-shared-worker-is-still-alive

//Interface for SharedWorker
(self as any).onconnect = (connectMessageEvent: MessageEvent) => {

  const port = connectMessageEvent.ports[0];
  
  port.onmessage = (messageEvent:MessageEvent) => {
  
    myWorker.processMessage(messageEvent);
  
  };

  port.start();

};

//Interface for Regular Worker
(self as any).onmessage = (messageEvent:MessageEvent) =>  {

  myWorker.processMessage(messageEvent);

};

type ListenerWindow = {
  lastPing: number;
  key: string;
  workerMessage: WorkerMessage;
}

export type RegisteredWindow = {
   
   port: any;
   callback: any;
   lastPing: number;
   topListListenerMessageId: number;
   topListListenerWindows: Map<string, ListenerWindow>;
   alertsListenerMessageId: number;
   alertsListenerWindows: Map<string, ListenerWindow>;
  
}

export type MarketDataSubscription = {
   
  lastMessage: string;
  registeredWindowIds: Array<string>;
 
}


/**
 * There are special processing channels implemented for the commands
 * top_list_listen, top_list_start, ms_alert_listen, ms_alert_start
 * The _listen commands can only be used once per user connenction, however,
 * subsequent calls to top_list_listen, overwrite the previous listener, while
 * subsequenet calls to ms_alert_listen are ignored. 
 * 
 * I wish I had the time to make this more generic. Hopefully I can revisit this
 * during a future maintenance window. 
 */

export class UserConnection
{
  constructor() {
    this.registeredWindows = new Map<string, RegisteredWindow>; 
    this.marketDataSubscriptions = new Map<string, MarketDataSubscription>;
  }

  public connectionMaster:ConnectionMaster | null = null;

  public registeredWindows: Map<string, RegisteredWindow>;

  public paperManagerWorker: PaperManagerWorker | null = null;

  public user?: User;

  public topListListener: boolean = false;
  public alertsListener: boolean = false;

  public marketDataSubscriptions: Map<string, MarketDataSubscription>; 

}

export class Worker{

  #userConnections: Map<string, UserConnection>;
  #checkCounter: number; 

  constructor(){

    this.#userConnections = new Map<string, UserConnection>;
    
    this.#checkCounter = 0;

    setInterval(this.checkRegisteredWindows.bind(this), 2100);

  }

  private createConnectionCallbacks(userName:string): ConnectionCallbacks  {
    
   return {
      onConnected: () => {
        this.broadCastMessage(userName, WorkerMessageType.Connected);
      },
      onDisconnected: () => {
        this.broadCastMessage(userName, WorkerMessageType.Disconnected);
      },
      onReconnected: () => {
        this.broadCastMessage(userName, WorkerMessageType.Reconnected);
      },
      onPingHealth: (pingMS: number) => {
        this.broadCastMessage(userName, WorkerMessageType.PingHealth, pingMS);
      },
      onDisconnectedForGood: () => {

        const userConnection = this.#userConnections.get(userName);
  
        if(userConnection)
          this.cleanupPaperTrading(userConnection);

        this.broadCastMessage(userName, WorkerMessageType.DisconnectedForGood);
      
      },
      onConnectionStatusUpdate: (status) => {
        this.broadCastMessage(userName, WorkerMessageType.ConnectionStatusUpdate, status);
      }
    }
  };

  private cleanupPaperTrading(userConnection: UserConnection){
    if(userConnection.paperManagerWorker){
      userConnection.paperManagerWorker.Disconnect();
      userConnection.paperManagerWorker = null;
    }
  }

  public processMessage(messageEvent:MessageEvent) :void
  {
    
    const workerMessage = messageEvent.data as WorkerMessage; 

    switch(workerMessage.type)
    {
       case WorkerMessageType.Login :
       {
          this.processLoginMessage(messageEvent, workerMessage);
          break;
       }
       case WorkerMessageType.SendWithNoResponse :
       {
          const messageToServer = createMessageToServer(workerMessage.message);
          
          TraceLoggingHelpers.log(`Request-SendWithNoResponse-${workerMessage.userName},${workerMessage.windowId},${getCommandName(messageToServer)},${workerMessage.messageId}`);
                    
          const connection = this.connectionFromUserName(workerMessage.userName)

          let shouldSend = true;

          if (connection){
            if(workerMessage.command == "top_list_stop" &&  workerMessage.listenerWindowId)
            {
              const registeredWindow = this.registeredWindowFromConnection(connection, workerMessage.windowId);
              if(registeredWindow){
                const channelWindow = registeredWindow.topListListenerWindows.get(workerMessage.listenerWindowId)
                if( channelWindow) 
                {
                  registeredWindow.topListListenerWindows.delete(workerMessage.listenerWindowId)
                } 
                else
                {
                  TraceLoggingHelpers.log('NoChannelWindow') 
                  shouldSend = false;
                } 
              }
            }
          }
          if(shouldSend)
          {
            connection?.connectionMaster?.outbox.sendWithNoResponse(messageToServer)
          } 

          break;
       }
       case WorkerMessageType.SendWithSingleResponse :
       {
          this.processSendWithSingleResponseMessage(workerMessage);
          break;
       }
       case WorkerMessageType.SendWithStreamingResponse :
       {
          this.processSendWithStreamingResponseMessage(workerMessage);
          break;
       }
       case WorkerMessageType.PaperRequest:
       {
          this.proxyPaperRequestMessage(workerMessage);
          break;
       }
       case WorkerMessageType.SubscribeMarketData:
       {
          this.processSubscribeMarketDataMessage(workerMessage);
          break;
       }
       case WorkerMessageType.UnSubscribeMarketData:
       {
          this.processUnSubscribeMarketDataMessage(workerMessage);
          break;
       }
       case WorkerMessageType.Ping :
       {
          const connection = this.connectionFromUserName(workerMessage.userName)
          if(connection)
          {
            const registeredWindow = this.registeredWindowFromConnection(connection, workerMessage.windowId);
            if(registeredWindow)
               registeredWindow.lastPing = performance.now()
          }  
          break;
       }
    }
  }

  public addRegisteredWindow(userName:string, windowId:string, callback:any){

    let userConnection = this.#userConnections.get(userName);
    if(!userConnection) return;

    const registeredWindow = { port: null,
                               callback:callback,   
                               lastPing: performance.now(), 
                               topListListenerMessageId: 0,
                               topListListenerWindows: new Map<string,ListenerWindow>(),
                               alertsListenerMessageId: 0,
                               alertsListenerWindows: new Map<string, ListenerWindow>()
                             };

    userConnection.registeredWindows.set(windowId, registeredWindow);

  }

  private processLoginMessage(messageEvent:MessageEvent, workerMessage:WorkerMessage){

    const logonRequest =  workerMessage.message as LoginRequest;

    TraceLoggingHelpers.log(`Login Request-${workerMessage.windowId},${logonRequest.userName}`);

    let userConnection = this.#userConnections.get(logonRequest.userName);

    if(!userConnection){
       userConnection = new UserConnection()
       userConnection.user = new User();
       userConnection.user.username = logonRequest.userName;
       this.#userConnections.set(logonRequest.userName, userConnection)
    }
    const registeredWindow = { port: messageEvent.target,
                               callback:null,   
                               lastPing: performance.now(), 
                               topListListenerMessageId: 0,
                               topListListenerWindows: new Map<string,ListenerWindow>(),
                               alertsListenerMessageId: 0,
                               alertsListenerWindows: new Map<string, ListenerWindow>()
                             };

    userConnection.registeredWindows.set(workerMessage.windowId, registeredWindow)

    if(userConnection.connectionMaster && userConnection.connectionMaster.loginManager.isLoggedIn())
    {
     
      const connectedMessage: WorkerMessage = {
        type: WorkerMessageType.Connected,
        userName: logonRequest.userName,
        windowId: workerMessage.windowId
      };

      this.sendMessage(registeredWindow, connectedMessage)

      const workerMessageResponse: WorkerMessage = {
          type: WorkerMessageType.Login,
          userName: logonRequest.userName,
          windowId: workerMessage.windowId,
          message: userConnection.user
        };

        TraceLoggingHelpers.log(`${workerMessage.windowId}:Worker already logged in with:${logonRequest.userName}`);
        
        this.sendMessage(registeredWindow, workerMessageResponse)
       
        return;
    }

    userConnection.connectionMaster = new ConnectionMaster()

    userConnection.connectionMaster.SetConnectionCallbacks( this.createConnectionCallbacks(logonRequest.userName))

    userConnection.connectionMaster.loginManager.login(logonRequest.location,
                                                       logonRequest.userName,
                                                       logonRequest.password,
                                                       logonRequest.applicationId,
                                                       logonRequest.destination,
                                                       logonRequest.traceLogging)
            .then((response: any ) => {

              if(response instanceof User ){
                const user = response as User
                if(user && userConnection){  
                  userConnection.user = user;

                  //We Start 1 instance of the Paper Trading Service for every user
                  //If the same user connects from a different browser window they share the same 
                  //instance of the PaperManagerWorker.
                  //The userConnection is injected into the PaperManagerWorker
                  //Paper Trading Requests are proxied through this worker 
                  //Paper Trading Responses are sent directly from the PaperManagerWorker via the 
                  //registeredWindows associated with the userConnection

                  this.cleanupPaperTrading(userConnection);

                  userConnection.paperManagerWorker = new PaperManagerWorker(this, userConnection);

                  userConnection.paperManagerWorker.Connect("Paper");
                }
              }  
             
              const workerMessageResponse: WorkerMessage = {
                 type: WorkerMessageType.Login,
                 userName: logonRequest.userName,
                 windowId: workerMessage.windowId,
                 message: response
               };
               TraceLoggingHelpers.log(`Login Response-${workerMessage.windowId},${logonRequest.userName}`);
               
               this.sendMessage(registeredWindow, workerMessageResponse )

            })   
            .catch((error: any) => {

              const workerMessageResponse: WorkerMessage = {
                type: WorkerMessageType.InvalidLogin,
                userName: logonRequest.userName,
                windowId: workerMessage.windowId,
                message: error
              };

              TraceLoggingHelpers.log(`Invalid Login Response-${workerMessage.windowId},${logonRequest.userName}`);
              
              this.sendMessage(registeredWindow, workerMessageResponse )
            
            });
  }

  private processSendWithSingleResponseMessage(workerMessage:WorkerMessage)
  {
          
    const messageToServer = createMessageToServer(workerMessage.message);

    TraceLoggingHelpers.log(`Request-SendWithSingleResponse-${workerMessage.userName},${workerMessage.windowId},${getCommandName(messageToServer)},${workerMessage.messageId}`);

    const connection = this.connectionFromUserName(workerMessage.userName)

    if (connection && workerMessage.listenerWindowId){
      
      const registeredWindow = this.registeredWindowFromConnection(connection, workerMessage.windowId);
    
      /**
       * 
       */
      if(registeredWindow)
      {

        /**
         * We are using the window_id from top_list_start and
         * the strategy_id from ms_alert_start to correlate responses to the correct user window
         * sicne all of the responses are received on the top_list_listen and ms_alert_listen
         * streaming channels  
         */
        if( workerMessage.command == "top_list_start"){
          registeredWindow.topListListenerWindows.set(workerMessage.listenerWindowId, {key: workerMessage.listenerWindowId, workerMessage: workerMessage, lastPing: performance.now()} )
        }
        else if( workerMessage.command == "ms_alert_start"){
          registeredWindow.alertsListenerWindows.set(workerMessage.listenerWindowId, {key: workerMessage.listenerWindowId, workerMessage: workerMessage, lastPing: performance.now()} )
        }
      }
    
    }

    connection?.connectionMaster?.outbox.sendWithSingleResponse(messageToServer)
    .promise
    .then((response: ResponseFromServer) => {
      
      const responseAsString = responseToString(response);

      const registeredWindow = this.registeredWindowFromConnection(connection, workerMessage.windowId);

      if(registeredWindow)
      {
        const workerMessageResponse: WorkerMessage = {
          type: WorkerMessageType.SendWithSingleResponse,
          userName: workerMessage.userName,
          windowId: workerMessage.windowId,
          messageId: workerMessage.messageId,
          message: responseAsString
        };

        TraceLoggingHelpers.log(`Response-SendWithSingleResponse-${workerMessage.userName},${workerMessage.windowId},${getCommandName(messageToServer)},${workerMessage.messageId}`);

        this.sendMessage(registeredWindow, workerMessageResponse )

      }

    });

  }

  private processSendWithStreamingResponseMessage(workerMessage:WorkerMessage)
  {

    const messageToServer = createMessageToServer(workerMessage.message);
 
    const command = workerMessage.command;

    if (command == "top_list_listen")
    {
      this.processTopListListenerAddMessage(workerMessage);
      return;
    } else if (command == "ms_alert_listen"){
      this.processAlertsListenerAddMessage(workerMessage);
      return;
    } 
    
    TraceLoggingHelpers.log(`Request-SendWithStreamingResponse-${workerMessage.userName},${workerMessage.windowId},${getCommandName(messageToServer)},${workerMessage.messageId}`);

    const connection = this.connectionFromUserName(workerMessage.userName) 

    connection?.connectionMaster?.outbox.sendWithStreamingResponse(messageToServer, (response: ResponseFromServer) => {

      const responseAsString = responseToString(response);

      const registeredWindow = this.registeredWindowFromConnection(connection, workerMessage.windowId);

      if(!registeredWindow)
      {
          if (command == "ms_top_list_start")
          {
            const name = messageToServer.get("name");
            connection?.connectionMaster?.outbox.sendWithNoResponse(createMessageToServer([["command", "ms_top_list_stop"],["name", name]]));
            TraceLoggingHelpers.log(`NoWindowResponse-SendWithStreamingResponse-${workerMessage.userName},${workerMessage.windowId},${command},${name},${workerMessage.messageId}`);
          }
          else{
            TraceLoggingHelpers.log(`NoWindowResponse-SendWithStreamingResponse-${workerMessage.userName},${workerMessage.windowId},${command},${workerMessage.messageId}`);
          }
      }
      else
      {
            const workerMessageResponse: WorkerMessage = {
              type: WorkerMessageType.SendWithStreamingResponse,
              userName: workerMessage.userName,
              windowId: workerMessage.windowId,
              messageId: workerMessage.messageId,
              message: responseAsString
            };

            TraceLoggingHelpers.log(`Response-SendWithStreamingResponse-${workerMessage.userName},${workerMessage.windowId},${getCommandName(messageToServer)},${workerMessage.messageId}`);

            this.sendMessage(registeredWindow, workerMessageResponse)
      }
    });

  }

  public subscribeMarketData(workerMessage:WorkerMessage){
    this.processSubscribeMarketDataMessage(workerMessage);
  }

  private processSubscribeMarketDataMessage(workerMessage:WorkerMessage)
  {
    
    const messageToServer = createMessageToServer(workerMessage.message);
 
    const command = workerMessage.command;

    const connection = this.connectionFromUserName(workerMessage.userName) 

    if (!connection) return;

    const symbol = workerMessage.message[2][1] as string

    const registeredWindow = this.registeredWindowFromConnection(connection, workerMessage.windowId);

    if (!registeredWindow) return;

    var subscription = connection.marketDataSubscriptions.get(symbol);
    
    if(!subscription)
    {
      subscription = {
        lastMessage: "",
        registeredWindowIds: [workerMessage.windowId]
      };

      connection.marketDataSubscriptions.set(symbol, subscription)
    }
    else{

      if(!subscription.registeredWindowIds.includes(workerMessage.windowId))
          subscription.registeredWindowIds.push(workerMessage.windowId);

      if( subscription.lastMessage.length > 0){
        this.sendMessage(registeredWindow, {
          type: WorkerMessageType.MarketDataResponse,
          userName: workerMessage.userName,
          windowId: workerMessage.windowId,
          message: subscription.lastMessage
        });
      }    

      return;

    }

    connection?.connectionMaster?.outbox.sendWithStreamingResponse(messageToServer, (response: ResponseFromServer) => {

      const responseAsString = responseToString(response);

      if(!registeredWindow)
      {
        TraceLoggingHelpers.log(`NoWindowResponse-SendWithStreamingResponse-${workerMessage.userName},${workerMessage.windowId},${command},${workerMessage.messageId}`);
      }
      else
      {
        let workerMessageResponse: WorkerMessage = {
          type: WorkerMessageType.MarketDataResponse,
          userName: connection.user?.username ?? "",
          message: responseAsString,
          windowId: ""
        };

        TraceLoggingHelpers.log(`Response-SendWithStreamingResponse-${workerMessage.userName},${workerMessage.windowId},${getCommandName(messageToServer)},${workerMessage.messageId}`);

        //var subscription = connection.marketDataSubscriptions.get(symbol);
        if(subscription){

          subscription.lastMessage = responseAsString ?? "";

          for (const windowId of subscription.registeredWindowIds ) {

            const responseRegisteredWindow = this.registeredWindowFromConnection(connection, windowId);
            if( responseRegisteredWindow){
              workerMessageResponse.windowId = windowId;
              this.sendMessage(responseRegisteredWindow, workerMessageResponse);
            }

          };
        }  
      }
    });

  }

  private processUnSubscribeMarketDataMessage(workerMessage:WorkerMessage){
    
    const symbol = workerMessage.message[2][1] as string;
    const connection = this.connectionFromUserName(workerMessage.userName)
    if(connection){
        this.removeMatchingWindowIdsFromMarketDataSubscriptionsBySymbol(connection, workerMessage.windowId, symbol);
    }

  }

  private proxyPaperRequestMessage(workerMessage:WorkerMessage)
  {

    const connection = this.connectionFromUserName(workerMessage.userName) 
     
    if(connection)
      connection.paperManagerWorker?.ProcessPaperRequestMessage( workerMessage);
  }

  private processTopListListenerAddMessage(workerMessage:WorkerMessage)
  {

    const messageToServer = createMessageToServer(workerMessage.message);

    const connection = this.connectionFromUserName(workerMessage.userName) 
     
    if(connection)
    {

      const registeredWindow = this.registeredWindowFromConnection(connection, workerMessage.windowId);
    
      if(registeredWindow)
      {
         registeredWindow.topListListenerMessageId = workerMessage.messageId ?? 0;
      }
      
      if(Boolean(connection?.topListListener))
      {
        return;
      }
      else
      {
        connection.topListListener = true;
      }

    }

    connection?.connectionMaster?.outbox.sendWithStreamingResponse(messageToServer, (response: ResponseFromServer) => {

      const responseAsString = responseToString(response);

      const xml = parseXml2(responseAsString);
      
      const listenerWindowId = xml?.getElementsByTagName("TOPLIST")[0]?.getAttribute("WINDOW") ?? "";

      const result = this.registeredWindowFromTopListsListenerWindowId(connection, listenerWindowId);

      if(result && result.registeredWindow && result.windowId )
      {
        const workerMessageResponse: WorkerMessage = {
          type: WorkerMessageType.SendWithStreamingResponse,
          userName: workerMessage.userName,
          windowId:  result.windowId,
          messageId: result.registeredWindow.topListListenerMessageId,
          message: responseAsString
        };

        TraceLoggingHelpers.log(`Response-SendWithStreamingResponse-${workerMessage.userName},${workerMessage.windowId},${getCommandName(messageToServer)},${workerMessage.messageId}`);
      
        this.sendMessage(result.registeredWindow, workerMessageResponse)

      }
      else
      {
        TraceLoggingHelpers.log(`Response-SendWithStreamingResponse-${workerMessage.userName},${workerMessage.windowId},${getCommandName(messageToServer)},${workerMessage.messageId}`);
          //Clean Up Here
      }

    });
  
  }

  private processAlertsListenerAddMessage(workerMessage:WorkerMessage)
  {

    const messageToServer = createMessageToServer(workerMessage.message);

    const connection = this.connectionFromUserName(workerMessage.userName) 
     
    if(connection)
    {

      const registeredWindow = this.registeredWindowFromConnection(connection, workerMessage.windowId);
    
      if(registeredWindow && !registeredWindow.alertsListenerMessageId)
      {
         registeredWindow.alertsListenerMessageId = workerMessage.messageId ?? 0;
      }
      
      if(Boolean(connection?.alertsListener))
      {
        return;
      }
      else
      {
        connection.alertsListener = true;
      }

    }

    connection?.connectionMaster?.outbox.sendWithStreamingResponse(messageToServer, (response: ResponseFromServer) => {

      const responseAsString = responseToString(response);

      const xml = parseXml2(responseAsString);

      const strategies = xml?.getElementsByTagName("STRATEGY");
      
      if(strategies){

        for (let i = 0; i < strategies.length; i++) {

          const listenerWindowId = strategies[i].getAttribute('ID') ?? "";
          
          const result = this.registeredWindowFromAlertsListenerWindowId(connection, listenerWindowId);

          if(result && result.registeredWindow && result.windowId )
          {
            let  workerMessageResponse: WorkerMessage = {
              type: WorkerMessageType.SendWithStreamingResponse,
              userName: workerMessage.userName,
              windowId:  result.windowId,
              messageId: result.registeredWindow.alertsListenerMessageId,
              message: responseAsString
            };
            TraceLoggingHelpers.log(`Response-SendWithStreamingResponse-${workerMessage.userName},${workerMessage.windowId},${getCommandName(messageToServer)},${workerMessage.messageId}`);
            this.sendMessage(result.registeredWindow, workerMessageResponse)
          }
          else{
            connection?.connectionMaster?.outbox.sendWithNoResponse(createMessageToServer([["command", "ms_alert_stop"],["strategy_id", listenerWindowId]]));
          }
        }
      }
      else
      {
        TraceLoggingHelpers.log(`Response-SendWithStreamingResponse-${workerMessage.userName},${workerMessage.windowId},${getCommandName(messageToServer)},${workerMessage.messageId}`);
          //Clean Up Here
      }

    });
  
  }

  private connectionFromUserName(userName: string): UserConnection | null | undefined{
    return this.#userConnections.get(userName);
  }

  private removeMatchingWindowIdsFromMarketDataSubscriptions(connection:UserConnection, windowIdToRemove: string) {
 
    // Iterate over each MarketDataSubscription in the map
    connection.marketDataSubscriptions.forEach((subscription) => {
      // Filter out the matching ids
      subscription.registeredWindowIds = subscription.registeredWindowIds.filter(
        id => id !== windowIdToRemove
      );
    });
 
    for (const [symbol, subscription] of connection.marketDataSubscriptions.entries()) {
      // Filter out the matching ids
      if( subscription.registeredWindowIds.length === 0)
      {

        connection.marketDataSubscriptions.delete(symbol);

        let message = [
          ["command",    "market_data"],
          ["subcommand", "unsubscribe"],
          ["symbol",      symbol]
        ]  as ServerCommand;
        const messageToServer = createMessageToServer(message);
        connection?.connectionMaster?.outbox.sendWithNoResponse(messageToServer)
      }
    };
  }
 
  private removeMatchingWindowIdsFromMarketDataSubscriptionsBySymbol(connection:UserConnection, windowIdToRemove: string, symbol: string) {
    
    let subscription = connection.marketDataSubscriptions.get(symbol);
    if( subscription)
    {
      subscription.registeredWindowIds = subscription.registeredWindowIds.filter(
        id => id !== windowIdToRemove  
      );
      if( subscription.registeredWindowIds.length === 0)
      {

        connection.marketDataSubscriptions.delete(symbol);

        let message = [
          ["command",    "market_data"],
          ["subcommand", "unsubscribe"],
          ["symbol",      symbol]
        ]  as ServerCommand;
        
        const messageToServer = createMessageToServer(message);
        
        connection?.connectionMaster?.outbox.sendWithNoResponse(messageToServer);
      }
    }

  }

  private registeredWindowFromTopListsListenerWindowId(connection: UserConnection, listenerWindowId: string): { registeredWindow: RegisteredWindow | undefined, windowId: string | undefined} | undefined {
    
    let registeredWindow: RegisteredWindow | undefined = undefined;
    
    let windowId: string | undefined = undefined;

    if (!connection) 
    {
        return undefined;
    }
    else 
    {
        for (const [key, value] of connection.registeredWindows.entries())
        {
            if (value.topListListenerWindows.get(listenerWindowId))
            {
                registeredWindow = value;
                windowId = key;
                break; // Break the loop once a match is found
            }
        }
    }

    return { registeredWindow, windowId }; 

  }

  private registeredWindowFromAlertsListenerWindowId(connection: UserConnection, listenerWindowId: string): { registeredWindow: RegisteredWindow | undefined, windowId: string | undefined} | undefined {
    
    let registeredWindow: RegisteredWindow | undefined = undefined;
    
    let windowId: string | undefined = undefined;

    if (!connection) 
    {
        return undefined;
    }
    else 
    {
        for (const [key, value] of connection.registeredWindows.entries())
        {
            if (value.alertsListenerWindows.get(listenerWindowId))
            {
                registeredWindow = value;
                windowId = key;
                break; // Break the loop once a match is found
            }
        }
    }

    return { registeredWindow, windowId }; 

  }

  private registeredWindowFromConnection(connection: UserConnection, windowId: string): RegisteredWindow | undefined {
    
    if(!connection)
      return undefined
    else
      return connection.registeredWindows.get(windowId);

  }

  private broadCastMessage(userName: string, workerMessageType:WorkerMessageType, data?:any | undefined) :void
  {

    let workerMessage: WorkerMessage = {
      type: workerMessageType,
      userName: userName,
      windowId: "",
      message: data
    }

    const connection = this.connectionFromUserName(workerMessage.userName) 

    if(connection)
    {
      connection.registeredWindows.forEach((value, key) => {
         workerMessage.windowId = key;
         this.sendMessage(value, workerMessage);
      });
    }
  }

  private sendMessage(registeredWindow:RegisteredWindow, message:WorkerMessage) :void
  {
    if( registeredWindow.callback)
    {
      if(message.type == WorkerMessageType.MarketDataResponse)
        registeredWindow.callback(message);
    }
    else if(registeredWindow.port)
       //This is a SharedWorker Client 
       registeredWindow.port.postMessage(message);
    else
       //This is a regular Worker Client
       window.postMessage(message);
  }

  /**
   *  This method does (3) things:
   *  1) Delete Registered Windows that have not send a ping for more than 30 seconds
   *  2) Send a ping to each registered window which triggers the registered window 
   *     to send a ping back. This mechanism exists because there is not an onClose event 
   *     for when a SharedWorker Client terminates. This pinging mechanism is used to 
   *     determine when a client goes away so any open streaming commands can be terminated
   *  3) Resend top_list_start commands that have not received a response after 2 seconds   
   */

  private checkRegisteredWindows()
  {

    for (const [connectionKey, connection] of this.#userConnections) {
      for (const [registeredWindowKey, registeredWindow] of connection.registeredWindows.entries()) {
        /**
         * do every 5th timer callback 
         */
        if(this.#checkCounter % 5 == 0)
        {

          //Do not remove the Market Data Registered Window from paper-manager-worker
          if (((performance.now() - registeredWindow.lastPing) > 30000) && registeredWindow.port) {

              TraceLoggingHelpers.log('Deleting-' + registeredWindowKey) 
              connection.registeredWindows.delete(registeredWindowKey);
              this.removeMatchingWindowIdsFromMarketDataSubscriptions(connection, registeredWindowKey);
          }
          else
          {
            this.sendMessage(registeredWindow,{
              type: WorkerMessageType.Ping,
              userName: connection.user?.username,
              windowId: registeredWindowKey
            } as WorkerMessage)
          }
        }
        /**
         * do every timer callback 
         */
        for (const [listenerWindowKey, listenerWindow] of registeredWindow.topListListenerWindows.entries()) {
          if ((performance.now() - listenerWindow.lastPing) > 2000) {
            registeredWindow.topListListenerWindows.delete(listenerWindow.key)
            this.processSendWithSingleResponseMessage( listenerWindow.workerMessage)
            TraceLoggingHelpers.log('ChannelWindowNoResponse-' + listenerWindow.key + '-' + listenerWindow.workerMessage.message) 
          }
        }
      }
    }
    this.#checkCounter++;
  }
}

const myWorker = new Worker();
