import { AppSettings } from "../app-settings";
import axios, { AxiosResponse, AxiosError } from 'axios';

export class SettingsClient {

    private static s_instance: SettingsClient | null = null;

    private constructor() {}

    public static getInstance(): SettingsClient {
 
        if (!this.s_instance) 
            this.s_instance = new SettingsClient();
         
         return this.s_instance;
    }

    public getMarketSchedule(markets:string): Promise<MarketSchedule[] | any> {
        return new Promise<MarketSchedule[] | any>((resolve,reject) => {
                  //Using Broker Services Endpoint 
                  axios.get<MarketSchedule>("https://" + AppSettings.getInstance().tiServicesHost + "/BrokersWebService/v1/Settings/market-schedule?markets=" + markets)
                  .then((response: any ) => {
                    if(response.status === 200){
                       resolve(response.data);
                     }
                    else{
                        reject(response.message);
                    }
                  })   
                  .catch((error: AxiosError) => {
                    if( error.response?.data)
                       reject(error.response?.data);
                    else
                       reject(error.message);
                  });         
        });
    }

}
export class MarketSchedule {
  public market = 'us_equities';
  public minutesUntilOpen = 570;
  public fullDayOpenMinutes = 390;
  public halfDayOpenMinutes = 210;
  public preMarketMinutes = 330;
  public postMarketMinutes = 240;
}