import { AlertHistoryClient } from "./alert-history.client";
import { ChartDataClient } from "./chart-data.client";
import { ChartIndicatorsClient } from "./chart-indicators.client";
import { CloudLinkClient } from "./cloud-link.client";
import { CompanyProfileClient } from "./company-profile.client";
import { HistogramClient } from "./histogram.client";
import { HolidayClient } from "./holiday.client";
import { MarketDataClient } from "./market-data.client";
import { ThrottledMarketDataClient } from "./throttled-market-data.client";
import { TraceLoggingHelpers } from "./models/helpers/trace-logging-helpers";
import { PriceAlertClient } from "./price-alert.client";
import { StreamingAlertsClient } from "./streaming-alerts.client";
import { SymbolListClient } from "./symbol-list.client";
import { TopListHistoryClient } from "./top-list-history.client";
import { TopListClient } from "./top-list.client";
import { makePromise, parseFloatX, dateIsValid } from "phil-lib/misc";
import { LoginRequest, WorkerMessage, WorkerMessageType, WorkerMessageFunction } from "../server-connection/worker"
import { ConnectionSettings } from "../connection-settings";
import { BrokerManagerClient } from "./broker-manager.client";
import { ProfitBackfillClient } from "./profit-backfill.client";
import { AiDataManagerClient } from "./ai-data-manager.client";
import { HaltedClient } from "./halted.client";
import { SettingsClient } from "./settings.client";
import { TradeWaveClient } from "./trade-wave.client";

declare const window: any;

export class User {
    public userId = 0;
    public username = '';
    public accountStatus = '';
  
    constructor(data?: any) {
      if (data) {
        this.userId = data.userId;
        this.username = data.username;
        this.accountStatus = data.accountStatus;
      }
    }
}

export type subscribeOnConnectedCancelToken = () => void;
export type subscribeOnConnectedListener = () => void;

export type subscribeOnDisconnectedCancelToken = () => void;
export type subscribeOnDisconnectedListener = () => void;

export type subscribeOnReconnectedCancelToken = () => void;
export type subscribeOnReconnectedListener = () => void;

export type subscribeOnDisconnectedForGoodCancelToken = () => void;
export type subscribeOnDisconnectedForGoodListener = () => void;

export type subscribeOnPingHealthCancelToken = () => void;
export type subscribeOnPingHealthListener = (pingMs: number) => void;
export type subscribeOnPingTimerCancelToken = () => void;
export type subscribeOnPingTimerListener = () => void;

export type subscribeConnectionStatusUpdateCancelToken = () => void;
export type subscribeConnectionStatusUpdateListener = (connected: boolean) => void;

export const COMMAND = "command";

export type ServerCommand = [string, string | number | null | undefined | boolean | Date][];

export type CancelToken = () => void;

export function decodeServerTime(source: undefined): undefined;
export function decodeServerTime(source: string | undefined): Date | undefined;
export function decodeServerTime(source: string | undefined): Date | undefined {
  const asNumber = parseFloatX(source);
  if (asNumber === undefined) {
    return undefined;
  } else {
    return new Date(asNumber * 1000);
  }
}

export function encodeDate(date: Date): number {
    //return dateIsValid(date) ? Math.round(date.valueOf() / 1000) : 0;
    return dateIsValid(date) ? Math.floor(date.valueOf() / 1000) : 0;
}

type WorkerListener = {
    forward(response: string): void;
};

export class Connection {
           
    private onConnectedListenerDict = new Map<string, subscribeOnConnectedListener>();
    private onDisconnectedListenerDict = new Map<string, subscribeOnDisconnectedListener>();
    private onReconnectedListenerDict = new Map<string, subscribeOnReconnectedListener>();
    private onDisconnectedForGoodListenerDict = new Map<string, subscribeOnDisconnectedForGoodListener>();
    private onPingHealthListenerDict = new Map<string, subscribeOnPingHealthListener>();
    private onPingTimerListenerDict = new Map<string, subscribeOnPingTimerListener>();
    private connectionStatusUpdateListenerDict = new Map<string, subscribeConnectionStatusUpdateListener>();

    #lastMessageId = 0;
    #worker:any;
    #windowId:string;
    #currentUser:User|null;
    #loginPromise:  { promise: Promise<User>, resolve: (value: User) => void, reject: (reason?: any) => void } | null = null;

    readonly #workerListeners = new Map<number, WorkerListener>();

    #workerPaperListener: any;
    #workerMarketDataListener: any;

    constructor() {

        this.#windowId = `wid-${this.getNewId()}`;
        this.#currentUser = null;
        this.#loginPromise = null;
     }

    private static instance: Connection | null = null;
    
    private getNewId(){
        return Math.random().toString(16).slice(2);
    } 

    public static getInstance(): Connection {
        
        if (!this.instance) {

            this.instance = new Connection();

            /***
            * 
            * The interface to the SharedWorker/Worker is implemented in services/worker.ts 
            * 
            * All the code under server-connection will run under the context of the SharedWorker/Worker
            * 
            * To debug the code you must open chrome developer tools using: chrome://inspect/#workers   
            * 
            */
            if( window.SharedWorker)
            { 
                this.instance.#worker = new SharedWorker("/pure-javascript-api/ti-js-api.iife.js");
            }
            else if( window.Worker) 
            {
                //When we are using a regular Worker, we assign the Worker to the port so we can call post message consistently between 
                //SharedWorker and Worker  
                this.instance.#worker = new Worker("/pure-javascript-api/ti-js-api.iife.js");
                this.instance.#worker.port = this.instance.#worker; //JS Magic 
            }
            else{

              //What do we do if we end up here ?
              alert( "Your web browser does not support TIPro Web.")
               
            } 
        
            this.instance.#worker.port.onmessage = (messageEvent:MessageEvent) => this.instance?.processMessage(messageEvent); 

        }

        return this.instance;
    }

    public softReset()
    {

    }

    public login(username: string, password: string, applicationId: number, destination?: string, traceLogging?: boolean): Promise<User | null> {
      
        this.#currentUser = new User();
        this.#currentUser.username = username;
        
        const loginWorkerMessage = {
            type: WorkerMessageType.Login,
            windowId: this.#windowId,
            userName: username,
            message:{  
                userName: username,
                password: password,
                applicationId: applicationId,
                destination:destination,
                traceLogging:traceLogging,
                location: window.location.href
            } as LoginRequest
        } as WorkerMessage;

        ConnectionSettings.setInstance(window.location.href, applicationId, username, password, destination, traceLogging);

        this.#worker.port.postMessage(loginWorkerMessage); 

        this.#loginPromise = makePromise<User>();
        
        return this.#loginPromise.promise;

    }

    public sendWithNoResponse(serverCommand: ServerCommand)
    {
        if(!this.#currentUser?.username ) {
            throw new Error("No Current User");
        };

        const workerMessage: WorkerMessage = {
            type: WorkerMessageType.SendWithNoResponse,
            userName: this.#currentUser?.username ?? "",
            windowId: this.#windowId,
            message: serverCommand,
            command: serverCommand[0][1] as string
        };

        if(workerMessage.command == "top_list_stop" ||
           workerMessage.command == "ms_alert_stop" )
        {
            workerMessage.listenerWindowId = serverCommand[1][1] as string;
        } 

        TraceLoggingHelpers.log(`Request-SendWithNoResponse-${serverCommand[0][1] + ' ' + serverCommand[1][1] },${this.#currentUser?.username},${workerMessage.windowId}`);

        this.#worker.port.postMessage(workerMessage);
    }

    public sendWithSingleResponse(serverCommand: ServerCommand): {cancel: CancelToken; promise: Promise<string | undefined>;} 
    {
        //I haven't decided the best way to handle this 
        if(!this.#currentUser?.username ) {
            throw new Error("No Current User");
        };

        this.#lastMessageId++;

        const messageId = this.#lastMessageId;

        const newPromise = makePromise<string|undefined>();

        const forward = (response: string) => {
           this.#workerListeners.delete(messageId);
           newPromise.resolve(response);
        };
          
        const listener: WorkerListener = {
          forward,
        };

        this.#workerListeners.set(messageId, listener);

        const cancel = () => {
           this.#workerListeners.delete(messageId);
           newPromise.resolve(undefined);
        };

        const workerMessage: WorkerMessage = {
            type: WorkerMessageType.SendWithSingleResponse,
            userName: this.#currentUser?.username,
            windowId: this.#windowId,
            messageId: messageId,
            message: serverCommand,
            command: serverCommand[0][1] as string
        };

        /**
         * This is the only command we need to do this for 
         * beacuse of the single use top_list_listen channel 
         */
        if(workerMessage.command == "top_list_start" ||
           workerMessage.command == "ms_alert_start")
        {
            workerMessage.listenerWindowId = serverCommand[1][1] as string;
        } 

        TraceLoggingHelpers.log(`Request-SendWithSingleResponse-${workerMessage.command}-${workerMessage.userName},${workerMessage.windowId},${workerMessage.messageId}`);

        this.#worker.port.postMessage(workerMessage);

        return {cancel, promise: newPromise.promise};
    
    }

    public subscribeMarketData(serverCommand: ServerCommand)
    {
        if(!this.#currentUser?.username ) {
            throw new Error("No Current User");
        };

        const command = serverCommand[0][1] as string

        const workerMessage: WorkerMessage = {
            type: WorkerMessageType.SubscribeMarketData,
            userName: this.#currentUser.username, 
            windowId: this.#windowId,
            message: serverCommand,
            command: command
        };

        TraceLoggingHelpers.log(`Request-SubscribeMarketData-${workerMessage.command}-${workerMessage.userName},${workerMessage.windowId},${workerMessage.messageId}`);

        this.#worker.port.postMessage(workerMessage);
    
    }

    public unSubscribeMarketData(serverCommand: ServerCommand)
    {
        if(!this.#currentUser?.username ) {
            throw new Error("No Current User");
        };

        const workerMessage: WorkerMessage = {
            type: WorkerMessageType.UnSubscribeMarketData,
            userName: this.#currentUser?.username ?? "",
            windowId: this.#windowId,
            message: serverCommand,
            command: serverCommand[0][1] as string
        };

        this.#worker.port.postMessage(workerMessage);
    }

    public sendWithStreamingResponse(serverCommand: ServerCommand, callback: (response: string) => void): CancelToken
    {
        if(!this.#currentUser?.username ) {
            throw new Error("No Current User");
        };

        this.#lastMessageId++;

        const messageId = this.#lastMessageId;
        const command = serverCommand[0][1] as string
        
        const forward = (response: string) => {
            TraceLoggingHelpers.log(`Response-SendWithStreamingResponse-${command},${workerMessage.userName},${workerMessage.windowId},${workerMessage.messageId}`);
            callback(response);
        };
          
        const listener: WorkerListener = {
          forward,
        };

        this.#workerListeners.set(messageId, listener);

        const cancel = () => {

            TraceLoggingHelpers.log(`Cancel-SendWithStreamingResponse-${command},${this.#currentUser?.username},${workerMessage.windowId},${messageId}`);
            this.#workerListeners.delete(messageId);
        };

        const workerMessage: WorkerMessage = {
            type: WorkerMessageType.SendWithStreamingResponse,
            userName: this.#currentUser.username, 
            windowId: this.#windowId,
            messageId: messageId,
            message: serverCommand,
            command: command
        };

        TraceLoggingHelpers.log(`Request-SendWithStreamingResponse-${workerMessage.command}-${workerMessage.userName},${workerMessage.windowId},${workerMessage.messageId}`);

        this.#worker.port.postMessage(workerMessage);

        return cancel;
    
    }

    public setPaperListener(callback: (workerMessage: WorkerMessage) => void){

        this.#workerPaperListener = callback;
    }

    public setMarketDataListener(callback: (message: string) => void){

        this.#workerMarketDataListener = callback;
    }

    public sendPaperRequestMessage(workerMessageFunction:WorkerMessageFunction, data:any )
    {
        if(!this.#currentUser?.username ) {
            throw new Error("No Current User");
        };
       
        const workerMessage: WorkerMessage = {
            type: WorkerMessageType.PaperRequest,
            function:  workerMessageFunction,
            userName: this.#currentUser.username, 
            windowId: this.#windowId,
            message: data
        };

        TraceLoggingHelpers.log(`Request-PaperRequest-${workerMessage.command}-${workerMessage.userName},${workerMessage.windowId},${workerMessage.messageId}`);

        this.#worker.port.postMessage(workerMessage);
    
    }

    private notifyWindowLoginSubscribers(): void {

        if (window.loginSubscribers && window.loginSubscribers.length) {
            for (let i = 0; i < window.loginSubscribers.length; i++) {
                window.loginSubscribers[i]();
            }

            window.loginSubscribers = [];
        }
    }

     /**
     *  All WorkerMessageTypes are processed in this function 
     *  within individual case statements.   
     *
     */
    private processMessage(messageEvent:MessageEvent):void{

        const workerMessage = messageEvent.data as WorkerMessage;  

        switch(workerMessage.type)
        {
            case WorkerMessageType.Login:
            {
                TraceLoggingHelpers.log(`Login Callback-${workerMessage.userName},${workerMessage.windowId}`);

                this.#currentUser = workerMessage.message as User;
                this.notifyWindowLoginSubscribers();
                this.#loginPromise?.resolve(this.#currentUser);
            
                break;
            }
            case WorkerMessageType.InvalidLogin:
            {
                TraceLoggingHelpers.log(`Invalid Login Callback-${workerMessage.userName},${workerMessage.windowId}`);
    
                this.#loginPromise?.reject(workerMessage.message);
    
                break;
            }
            case WorkerMessageType.SendWithSingleResponse:
            {
                TraceLoggingHelpers.log(`Response-SendWithSingleResponse-${workerMessage.userName},${workerMessage.windowId},${workerMessage.messageId}`);

                this.#workerListeners.get(workerMessage.messageId ?? 0)?.forward(workerMessage.message);
                
                break;
            }
            case WorkerMessageType.SendWithStreamingResponse:
            {
                const listener = this.#workerListeners.get(workerMessage.messageId ?? 0);

                if( listener)
                {
                    TraceLoggingHelpers.log(`Response-SendWithStreamingResponse-${workerMessage.userName},${workerMessage.windowId},${workerMessage.messageId}`);
                    listener.forward(workerMessage.message);
                }
                else
                {
                    TraceLoggingHelpers.log(`Response-SendWithStreamingResponse-NoListener-${workerMessage.userName},${workerMessage.windowId},${workerMessage.messageId}`);
                }
                
                break;
            }
            case WorkerMessageType.PaperResponse:
            {
                TraceLoggingHelpers.log(`Response-PaperResponse-${workerMessage.userName},${workerMessage.windowId},${workerMessage.messageId}`);
    
                if(this.#workerPaperListener)
                    this.#workerPaperListener(workerMessage);

                break;
            }
            case WorkerMessageType.MarketDataResponse:
            {
                TraceLoggingHelpers.log(`Response-MarketDataResponse-${workerMessage.userName},${workerMessage.windowId},${workerMessage.messageId}`);
    
                if(this.#workerMarketDataListener)
                    this.#workerMarketDataListener(workerMessage.message);

                break;
            }

            case WorkerMessageType.Connected:
            {
                TraceLoggingHelpers.log(`Connected Callback-${workerMessage.userName},${workerMessage.windowId}`);

                this.onConnected();
                break;
            }
            case WorkerMessageType.Reconnected:
            {
                this.onReconnected();
                break;
            }
            case WorkerMessageType.Disconnected:
            {
                this.onDisconnected();
                break;
            }
            case WorkerMessageType.DisconnectedForGood:
            {
                this.onDisconnectedForGood();
                break;
            }
            case WorkerMessageType.PingHealth:
            {
                this.onPingHealth(workerMessage.message );
                break;
            }
            case WorkerMessageType.ConnectionStatusUpdate:
            {
                this.onConnectionStatusUpdate(workerMessage.message);
                break;
            }
            case WorkerMessageType.Ping :
            {
                this.#worker.port.postMessage({
                    type: WorkerMessageType.Ping,
                    userName: this.#currentUser?.username ?? "",
                    windowId: this.#windowId
                } as WorkerMessage);

               this.onPingTimer();
            }
        }
    }

    public logout() {}

    public reconnect() {}
  
    public isLoggedIn(): boolean {
        return !!this.#currentUser?.username && this.#currentUser?.accountStatus?.toLowerCase() == 'good';
    }

    public isDemo(): boolean {
        return !!this.#currentUser?.username && this.#currentUser?.username.toLowerCase() == 'demo';
    }

    public getCurrentUser(): User | null {
        return this.#currentUser;
    }

    public get id(): string {
        return this.#windowId;
    }

    public get alertHistoryClient(): AlertHistoryClient {
        return new AlertHistoryClient();
    }

    public get chartDataClient(): ChartDataClient {
        return new ChartDataClient();
    }

    public get cloudLinkClient(): CloudLinkClient {
        return new CloudLinkClient();
    }

    public get companyProfileClient(): CompanyProfileClient {
        return new CompanyProfileClient();
    }

    public get histogramClient(): HistogramClient {
        return new HistogramClient();
    }

    public get holidayClient(): HolidayClient {
        return new HolidayClient();
    }

    public get streamingAlertsClient(): StreamingAlertsClient {
        return new StreamingAlertsClient();
    }

    public get symbolListClient(): SymbolListClient {
        return new SymbolListClient();
    }

    public get aiDataManagerClient(): AiDataManagerClient {
        return AiDataManagerClient.GetInstance();
    }

    public get topListHistoryClient(): TopListHistoryClient {
        return new TopListHistoryClient();
    }

    public get topListClient(): TopListClient {
        return new TopListClient();
    }

    public get priceAlertClient(): PriceAlertClient {
        return new PriceAlertClient();
    }

    

    public get throttledMarketDataClient(): ThrottledMarketDataClient {
        return ThrottledMarketDataClient.GetInstance();
    }


    public get marketDataClient(): MarketDataClient {
        return MarketDataClient.GetInstance();
    }
    public get brokerManagerClient(): BrokerManagerClient  {
        return BrokerManagerClient.GetInstance();
    }
    
    public get profitBackfillClient(): ProfitBackfillClient {
        return new ProfitBackfillClient();
    }
    
    public get haltedClient(): HaltedClient {
        return new HaltedClient();
    }

    public get chartIndicatorsClient(): ChartIndicatorsClient {
        return new ChartIndicatorsClient();
    }

    public get settingsClient(): SettingsClient {
        return SettingsClient.getInstance();

    }    
    public get tradeWaveClient(): TradeWaveClient {
        return new TradeWaveClient();
    }
    
    public subcribeToLoginEvent(functionCall: Function) {
        const loginSubscribersDoesNotExist = !window.loginSubscribers || !window.loginSubscribers.length;
        if (loginSubscribersDoesNotExist) {
            window.loginSubscribers = [];
        }

        const alreadyLoggedIn = this.isLoggedIn();
        if (alreadyLoggedIn) {
            TraceLoggingHelpers.log(`Connection, subcribeToLoginEvent, already Logged In calling functionCall...`)
            functionCall();
        } else {
            TraceLoggingHelpers.log(`Connection, subcribeToLoginEvent, not Logged In yet, adding loginWatcher for functionCall...`)

            window.loginSubscribers.push(() => {
                functionCall();
            });
        }
    }    

    private onConnectionStatusUpdate(connected: boolean): void {
        this.connectionStatusUpdateListenerDict.forEach((value: subscribeConnectionStatusUpdateListener) => {
          value(connected);
        });
    }
    
    private onDisconnectedForGood(): void {

        this.#workerPaperListener = null;

        BrokerManagerClient.GetInstance().Disconnect();

        this.onDisconnectedForGoodListenerDict.forEach((value: subscribeOnDisconnectedForGoodListener) => {
          value();
        });
    
    }  
    
    
    private onPingTimer(): void {
        this.onPingTimerListenerDict.forEach((value: subscribeOnPingTimerListener) => {
          value();
        });
      }  

    private onPingHealth(pingMs: number): void {
      this.onPingHealthListenerDict.forEach((value: subscribeOnPingHealthListener) => {
        value(pingMs);
      });
    }  
    
    private onConnected(): void {
      this.onConnectedListenerDict.forEach((value: subscribeOnConnectedListener) => {
        value();
      });
    }  
    
    private onDisconnected(): void {
        this.onDisconnectedListenerDict.forEach((value: subscribeOnDisconnectedListener) => {
          value();
        });
    }    
    
    private onReconnected(): void {
        this.onReconnectedListenerDict.forEach((value: subscribeOnReconnectedListener) => {
          value();
        });
    }   

    public subscribeConnectionStatusUpdate(listener: subscribeConnectionStatusUpdateListener): subscribeConnectionStatusUpdateCancelToken {
        const cancelId = this.getNewId();
    
        const cancelToken: subscribeConnectionStatusUpdateCancelToken = () => {
          this.connectionStatusUpdateListenerDict.delete(cancelId);
        };
    
        this.connectionStatusUpdateListenerDict.set(cancelId, listener);
        return cancelToken;
    }
    
    public subscribeOnConnected(listener: subscribeOnConnectedListener): subscribeOnConnectedCancelToken {
        const cancelId =  this.getNewId();
        
        const cancelToken: subscribeOnConnectedCancelToken = () => {
          this.onConnectedListenerDict.delete(cancelId);
        };
    
        this.onConnectedListenerDict.set(cancelId, listener);
    
        return cancelToken;
    }  
      
    public subscribeOnDisconnected(listener: subscribeOnDisconnectedListener): subscribeOnDisconnectedCancelToken {
        const cancelId =  this.getNewId();;
        
        const cancelToken: subscribeOnDisconnectedCancelToken = () => {
          this.onDisconnectedListenerDict.delete(cancelId);
        };
    
        this.onDisconnectedListenerDict.set(cancelId, listener);
    
        return cancelToken;
    }    
    
    public subscribeOnReconnected(listener: subscribeOnReconnectedListener): subscribeOnReconnectedCancelToken {
        const cancelId =  this.getNewId();
        
        const cancelToken: subscribeOnReconnectedCancelToken = () => {
          this.onReconnectedListenerDict.delete(cancelId);
        };
    
        this.onReconnectedListenerDict.set(cancelId, listener);
    
        return cancelToken;
    }  
    
    public subscribeOnDisconnectedForGood(listener: subscribeOnDisconnectedForGoodListener): subscribeOnDisconnectedForGoodCancelToken {
        const cancelId =  this.getNewId();
        
        const cancelToken: subscribeOnDisconnectedForGoodCancelToken = () => {
          this.onDisconnectedForGoodListenerDict.delete(cancelId);
        };
    
        this.onDisconnectedForGoodListenerDict.set(cancelId, listener);
    
        return cancelToken;
    }
    
    public subscribeOnPingHealth(listener: subscribeOnPingHealthListener): subscribeOnPingHealthCancelToken {
        const cancelId =  this.getNewId();
        
        const cancelToken: subscribeOnPingHealthCancelToken = () => {
          this.onPingHealthListenerDict.delete(cancelId);
        };
    
        this.onPingHealthListenerDict.set(cancelId, listener);
    
        return cancelToken;
    }
    
    public subscribeOnPingTimer(listener: subscribeOnPingTimerListener): subscribeOnPingTimerCancelToken {
        const cancelId =  this.getNewId();
        
        const cancelToken: subscribeOnPingTimerCancelToken = () => {
          this.onPingTimerListenerDict.delete(cancelId);
        };
    
        this.onPingTimerListenerDict.set(cancelId, listener);
    
        return cancelToken;
    }

}