export class TradeWaveRequestModel {
    public timeframe = '10m';
    public symbol = 'SPY';
    public direction = 'long';
    public preMarket = false;
    public postMarket = false;
    public entryCloseAboveTop = false;
    public entryCloseAboveBottom = false;
    public exitCloseBelowTop = false;
    public exitCloseBelowBottom = false;
    public cloudNumber = 2;
    public differenceAboveTop?: number;
    public differenceBetweenBands?: number;
}