import { DateTime } from "luxon";
export class DateHelpers {
    public static fullMonths: Array<string> = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    public static shortMonths: Array<string> = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ];

    public static fullDays: Array<string> = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Satuarday'
    ];

    public static format(format: string, date: Date): string {
        const dateString = format
            .replace('yyyy', date.getFullYear() + '')
            .replace('mm', (date.getMonth() + 1) <= 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1) + '')
            .replace('SM', DateHelpers.shortMonths[date.getMonth()])
            .replace('FM', DateHelpers.fullMonths[date.getMonth()])

            .replace('dd', date.getDate() <= 9 ? '0' + date.getDate() : date.getDate() + '')

            .replace('HH', date.getHours() <= 9 ? '0' + date.getHours() : date.getHours() + '')
            .replace('SH', DateHelpers.getShortHours(date.getHours()))

            .replace('MM', date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes() + '')

            .replace('s', date.getSeconds() <= 9 ? '0' + date.getSeconds() : date.getSeconds() + '')
            .replace('tt', date.getHours() >= 12 ? 'PM' : 'AM')
            
            .replace('yy', (date.getFullYear() + '').substring(2, 4))
            .replace('m', (date.getMonth() + 1) + '')
            .replace('d', date.getDate() + '');

        return dateString;
    }

    public static getHoursDifferent(first: Date, second: Date): number {
        return Math.abs(first.valueOf() - second.valueOf()) / 36e5;
    }

    public static getMinutesDifferent(first: Date, second: Date): number {
        return Math.abs(first.valueOf() - second.valueOf()) / (60 * 1000);
    }

    public static getMinutesDifferentNoAbs(first: Date, second: Date): number {
        return (first.valueOf() - second.valueOf()) / (60 * 1000);
    }

    public static toMarketTimeZone(date: Date): DateTime {
        return DateTime.fromJSDate(date, {
            zone: 'America/New_York'
        });
    }

    public static getNowInMarketTimeZone(): DateTime {
        return DateTime.fromJSDate(new Date(), {
            zone: 'America/New_York'
        });
    }

    public static getNextWeekday(date: DateTime): DateTime {
        if (date.weekday == 6)
            date = date.plus({days: 2});
        if (date.weekday == 7)
            date = date.plus({days: 1});
        return date;
    }

    public static addWeekDays(date: DateTime, days: number): DateTime {
        if (days <= 0) {
            return date;
        }

        if (date.weekday == 6) {
            date = date.plus({days: 2});
            days -= 1;
        }
        else if (date.weekday == 7) {
            date = date.plus({days: 1});
            days -= 1;
        }

        date = date.plus({days: days / 5 * 7});
        let extraDays = days % 5;

        if (date.day + extraDays > 5) {
            extraDays += 2;
        }

        return date.plus({days: extraDays});
    }

    public static isWeekend(date: Date): boolean {
        return date.getDay() == 6 || date.getDay() == 0;
    }

    private static getShortHours(hours: number): string {
        let h = hours % 12;
        if (h > 0) {
            if (h <= 9) {
                return '0' + h;
            }

            return h + '';
        }

        return '12';
    }
}